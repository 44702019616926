import Amplify from 'aws-amplify';

const endpoints = [
  'pos-categories',
  'pos-products',
  'uploads',
  'sale-points',
  'pos-products-sales',
  'pos-products-daily-trade',
  'warehouses',
  'pos-discounts',
  'locations',
  'tax-rates',
  'pos-products-order',
  'pos-categories-order',
  'pos-products-prices',
  'company-profiles',
  'community-posts',
  'community-users',
  'poll-questions',
  'poll-answers',
  'company-services',
  'community-uploads',
  'product-portfolio',
].map((name) => ({
  name,
  endpoint: process.env.REACT_APP_AWS_API_GATEWAY_URL,
  region: process.env.REACT_APP_AWS_API_GATEWAY_REGION,
}));

const warehouseEndpoints = [
  'products',
].map((name) => ({
  name,
  endpoint: process.env.REACT_APP_AWS_WH_API_GATEWAY_URL,
  region: process.env.REACT_APP_AWS_API_GATEWAY_REGION,
}));

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
  },
  Storage: {
    bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    region: process.env.REACT_APP_AWS_S3_REGION,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [...endpoints, ...warehouseEndpoints],
  },
});
