import { Post, Poll } from './types';

export interface CommunityState {
  posts: Post[] | null;
  postLoading: boolean;
  postCreating: boolean;
  postLastFetched: Date | null,
  polls: Poll[];
  pollLoading: boolean;
  pollsCreating: boolean;
  answerLoading: boolean;
}

export const initialState: CommunityState = {
  posts: null,
  postLoading: false,
  postCreating: false,
  postLastFetched: null,
  polls: [],
  pollLoading: false,
  pollsCreating: false,
  answerLoading: false,
};
