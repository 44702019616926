import * as TYPES from 'consts/companiesActionTypes';
import { CompanyServiceDto, CreateCompany, DropdownEntry } from 'reducers/companies/types';

export const getCompanyList = (): TYPES.GetCompanyListRequestedType => ({
  type: TYPES.COMPANIES_GET_COMPANY_LIST_R,
});

export const createCompany = (formData: CreateCompany): TYPES.CreateCompanyRequestedType => ({
  type: TYPES.COMPANIES_CREATE_COMPANY_R,
  payload: {
    formData,
  },
});

export const setCreateCompanyField = (field: string, value: string) => ({
  type: TYPES.SET_CREATE_COMPANY_FIELD,
  payload: {
    field,
    value,
  },
});

export const getCompanyServiceList = (name: string): TYPES.GetCompanyServiceListRequestedType => ({
  type: TYPES.GET_COMPANY_SERVICE_LIST_R,
  payload: {
    name,
  },
});

export const createCompanyService = (companyService: CompanyServiceDto, type: string): TYPES.CreateCompanyServiceRequestedType => ({
  type: TYPES.CREATE_COMPANY_SERVICE_R,
  payload: {
    companyService,
    type,
  },
});

export const addServiceToCompany = (companyService: DropdownEntry, type: string): TYPES.AddServiceToCompany => ({
  type: TYPES.ADD_SERVICE_TO_COMPANY,
  payload: {
    companyService,
    type,
  },
});

export const deleteServiceFromCompany = (companyService: string, type: string): TYPES.DeleteServiceFromCompany => ({
  type: TYPES.DELETE_SERVICE_FROM_COMPANY,
  payload: {
    companyService,
    type,
  },
});

export const getCompany = (id: string): TYPES.GetCompanyRequestedType => ({
  type: TYPES.GET_COMPANY_R,
  payload: {
    id,
  },
});

export const editCompany = (formData: CreateCompany): TYPES.EditCompanyRequestedType => ({
  type: TYPES.COMPANIES_EDIT_COMPANY_R,
  payload: {
    formData,
  },
});

export const editCompanySucceeded = (): TYPES.EditCompanySucceededActionType => ({
  type: TYPES.COMPANIES_EDIT_SUCCEEDED,
});

export const getProductPortfolioList = (name: string): TYPES.GetProductPortfolioListRequestedType => ({
  type: TYPES.GET_PRODUCT_PORTFOLIO_LIST_R,
  payload: {
    name,
  },
});

export const createProductPortfolio = (name: string, keywords: string[]): TYPES.CreateProductPortfolioRequestedType => ({
  type: TYPES.CREATE_PRODUCT_PORTFOLIO_R,
  payload: {
    productPortfolio: { name, keywords },
  },
});

export const editProductPortfolio = (_id: string, name: string, keywords: string[]): TYPES.EditProductPortfolioRequestedType => ({
  type: TYPES.EDIT_PRODUCT_PORTFOLIO_R,
  payload: {
    productPortfolio: { _id, name, keywords },
  },
});

export const deleteProductPortfolio = (id: string): TYPES.DeleteProductPortfolioRequestedType => ({
  type: TYPES.DELETE_PRODUCT_PORTFOLIO_R,
  payload: {
    id,
  },
});

export const searchCompanyListByProducts = (productPortfolioIds: string[]): TYPES.SearchCompanyListRequestedType => ({
  type: TYPES.COMPANIES_SEARCH_COMPANY_LIST_R,
  payload: {
    productPortfolioIds,
  },
});

export const initSearchCompanyListByProducts = (): TYPES.SearchCompanyListInitType => ({
  type: TYPES.COMPANIES_SEARCH_COMPANY_LIST_INIT,
});
