import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import { createPost } from 'actions/communityActions';
import { RootState } from 'reducers/rootReducer';
import TextEditor from 'components/TextEditor/TextEditor';
import './create-post.scss';

export interface CreatePostProps {
  userName: string;
}

const CreatePost: FC<CreatePostProps> = ({ userName }) => {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(EditorState.createEmpty());

  const {
    community: { postCreating },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (!postCreating) {
      setIsEditing(false);
    }
  }, [postCreating]);

  return (
    <div className="create-post">
      <div className="create-post-header">
        <div className="create-post-header-text">
          <div className="create-post-header-title">{`Üdvözöljük, ${userName}!`}</div>
          <div className="create-post-header-subtitle">Készen állunk, vágjunk bele!</div>
        </div>
        {
          !isEditing
          && (
            <Button
              type="primary"
              label="új bejegyzés létrehozása"
              style={{ padding: '0 12px' }}
              onClick={() => {
                setIsEditing(true);
              }}
            />
          )
        }
      </div>
      {
        isEditing
        && (
          <>
            <TextEditor
              content={content}
              onChange={setContent}
            />
            <div className="main-page-button-group">
              <Button
                label="MÉGSEM"
                onClick={() => setIsEditing(false)}
              />
              <Button
                type="primary"
                label="BEJEGYZÉS LÉTREHOZÁSA"
                loading={postCreating}
                disabled={postCreating}
                onClick={() => {
                  const currentContent = draftToHtml(convertToRaw(content.getCurrentContent()));
                  dispatch(createPost(currentContent));
                }}
              />
            </div>
          </>
        )
      }
    </div>
  );
};

export default CreatePost;
