import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout, { MenuType } from 'layouts/MainLayout/MainLayout';
import { RootState } from 'reducers/rootReducer';
import { answerPoll, getPolls } from 'actions/communityActions';
import Loader from 'components/Loader/Loader';
import HighlightedInfo from 'components/HighlightedInfo/HighlightedInfo';
import WhatsNew from 'components/WhatsNew/WhatsNew';
import Poll from 'components/Poll/Poll';
import CreatePollContainer from 'components/CreatePoll/CreatePollContainer';
import { Answer } from 'reducers/community/types';
import './vote-page.scss';

const VotePage = () => {
  const dispatch = useDispatch();
  const {
    user: { isAdmin, userId, givenName },
    community: { pollLoading, polls, answerLoading },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(getPolls());
  }, [dispatch]);

  const onAnswer = (id: string, answers: Answer[]) => {
    const answer = answers.find((item) => item._id === id);
    const hasAnswered = answer ? answer.userIds.includes(userId) : false;
    if (!hasAnswered) {
      dispatch(answerPoll(id, answers, userId));
    }
  };

  return (
    <MainLayout
      selectedMenu={MenuType.VOTES}
      rightPanel={(
        <Row gutter={[0, 36]}>
          <Col
            span={24}
            lg={0}
            sm={0}
            xl={24}
          >
            <HighlightedInfo
              title="🚀 START!"
              date="2021-09-25"
              content={`
                Köszöntjük a Nagytarcsa Ipari Terület közösségi felületén!
                A mai nappal elindult a közösségi webalkalmazás, a következő hetekben további funkciókkal bővül majd a szolgáltatás.
              `}
            />
          </Col>
          <Col
            span={24}
            lg={0}
            sm={0}
            xl={24}
          >
            <WhatsNew
              items={[
                { date: '2021-09-25', update: 'Hírfolyam oldal' },
                { date: '2021-09-24', update: 'Cégtérkép' },
                { date: '2021-09-23', update: 'Cég- és szolgáltatáskereső' },
              ]}
            />
          </Col>
        </Row>
      )}
    >
      {isAdmin && <CreatePollContainer userName={givenName} />}
      <Loader isLoading={pollLoading} pageLoader>
        {
          polls.length
            ? polls.map((item) => (
              <div className="vote-page-poll-container">
                <Loader isLoading={answerLoading}>
                  <Poll
                    poll={item}
                    allAnswers={item.options.reduce((acc, curr) => acc + curr.userIds.length, 0)}
                    onAnswer={onAnswer}
                  />
                </Loader>
              </div>
            ))
            : <div className="empty-block">Nincs megjelenítendő szavazás</div>
        }
      </Loader>
    </MainLayout>
  );
};
export default VotePage;
