import React, { FC, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faUserSecret, faKey, faUser } from '@fortawesome/pro-duotone-svg-icons';
import { IconInput, Button } from '@flexboxapps/flbx-webapp-ui';
import { forgotPassword } from 'actions/userActions';
import { validatePassword } from 'services/services';

export interface RestorePasswordProps {
  tokenParam: string;
  emailParam: string;
}

const RestorePassword: FC<RestorePasswordProps> = ({ tokenParam, emailParam }) => {
  const [password, setPassword] = useState('');
  const [code, setCode] = useState(tokenParam);
  const [email, setEmail] = useState(emailParam);
  const passwordRef = useRef(password);
  const codeRef = useRef(code);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [error, setError] = useState({
    passwordError: false,
    codeError: false,
  });

  const setPasswordError = (passwordValue: string) => {
    if (validatePassword(passwordValue)) {
      setError({ ...error, passwordError: validatePassword(passwordValue) });
      return true;
    }
    setError({ ...error, passwordError: false });
    return null;
  };

  const setCodeError = (codeValue: string) => {
    if (!codeValue || codeValue === '') {
      setError({ ...error, codeError: true });
      return true;
    }
    setError({ ...error, codeError: false });
    return null;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      dispatch(forgotPassword(passwordRef.current, codeRef.current, email));
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleRestorePassword = () => {
    if (!setPasswordError(password) && !setCodeError(code)) {
      dispatch(forgotPassword(password, code, email));
    }
  };

  return (
    <div className="login-container">
      <div className="input-container">
        <IconInput
          value={code}
          placeholder={t('pages.login.inputs.code')}
          iconName={faUserSecret}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (error.codeError) {
              setCodeError(e.target.value);
            }
            setCode(e.target.value);
            codeRef.current = e.target.value;
          }}
          onBlur={() => { setCodeError(code); }}
          type="password"
        />
      </div>
      {
        error.codeError && (
          <div className="error-message">
            {t('pages.retorePassword.errors.emptyPassword') as string}
          </div>
        )
      }
      <div className="input-container">
        <IconInput
          value={email}
          placeholder={t('pages.login.inputs.email')}
          iconName={faUser}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (error.codeError) {
              setCodeError(e.target.value);
            }
            setEmail(e.target.value);
            codeRef.current = e.target.value;
          }}
          onBlur={() => { setCodeError(code); }}
          type="text"
        />
      </div>
      {
        error.codeError && (
          <div className="error-message">
            {t('pages.retorePassword.errors.emptyPassword') as string}
          </div>
        )
      }
      <div className="input-container">
        <IconInput
          value={password}
          placeholder={t('pages.login.inputs.password')}
          iconName={faKey}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const currentValue = e.target.value;
            if (error.passwordError) {
              setPasswordError(currentValue);
            }
            if (currentValue.length >= 8) {
              setPasswordError(currentValue);
            }

            setPassword(currentValue);
            passwordRef.current = currentValue;
          }}
          onBlur={() => { setPasswordError(password); }}
          type="password"
        />
      </div>
      {
        error.passwordError && (
          <div className="error-message">
            {t('pages.retorePassword.errors.wrongPassword') as string}
          </div>
        )
      }
      <div className="restore-password-button-container">
        <Button
          label={t('pages.retorePassword.inputs.button') as string}
          onClick={handleRestorePassword}
          type="primary"
          disabled={error.codeError || error.passwordError}
        />
      </div>
    </div>
  );
};

export default RestorePassword;
