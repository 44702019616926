import React, { FC, useEffect, useState } from 'react';
import { Input } from '@flexboxapps/flbx-webapp-ui';

export interface SmartInputProps {
  label: string;
  initialValue: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string, name: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (value: string, name: string, event: React.FocusEvent<HTMLInputElement>) => void;
}

const SmartInput: FC<SmartInputProps> = ({
  label,
  initialValue,
  name,
  placeholder = '',
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
}) => {
  const [ownValue, setOwnValue] = useState(initialValue);

  useEffect(() => {
    setOwnValue(() => (initialValue));
  }, [initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOwnValue(() => (value));
    onChange(value, name, event);
  };

  const handleBlur = (event: React.FocusEvent<any>) => {
    const { value } = event.target;
    onBlur(value, name, event);
  };

  return (
    <Input
      value={ownValue}
      label={label}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default SmartInput;
