import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { useNavigate } from 'react-router-dom';
import NavigationRoute from 'routes/NavigationRoute';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint } from '@fortawesome/pro-regular-svg-icons';
import Login from 'components/Login/Login';
import { ReactComponent as LogoWideInverse } from 'assets/logo-wide-inverse.svg';
import { ReactComponent as LogoWide } from 'assets/logo-wide-normal.svg';
import './login-page.scss';

const LoginPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(({ user }: RootState) => user);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(NavigationRoute.Posts);
    }
  }, [isAuthenticated]);

  return (
    <div className="page page-login">
      <div className="logo">
        <LogoWide className="logo-normal" />
        <LogoWideInverse width="196" className="logo-inverse" />
      </div>
      <div className="login-box">
        <div className="header-container">
          <FontAwesomeIcon icon={faFingerprint} />
          <div>
            <div className="form-title">{t('pages.login.title') as string}</div>
            <div className="form-subtitle">{t('pages.login.subtitle') as string}</div>
          </div>
        </div>
        <Login />
      </div>
    </div>
  );
};

export default LoginPage;
