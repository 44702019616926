import React, { FC, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import { GoogleMap as GoogleMapComponent, KmlLayer, Marker, InfoWindow } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/pro-solid-svg-icons';
import { googleMapId } from 'services/config';
import { Company, LatLang } from 'reducers/companies/types';
import './google-map.scss';

export interface GoogleMapProps {
  companyProfiles?: Company[];
  defaultPosition: LatLang;
  size?: number;
  onMarkerChange?: (position: LatLang) => void;
  onMarkerClick?: (company: Company) => void;
  zoom?: number;
  containerStyle?: React.CSSProperties;
  gestureHandling?: string;
  selected?: boolean;
  selectedCompany?: Company | null;
}

const mapTimestamp = moment().unix();

const GoogleMap: FC<GoogleMapProps> = (props) => {
  const {
    defaultPosition,
    companyProfiles = [],
    size = null,
    onMarkerChange = undefined,
    onMarkerClick,
    zoom = 16,
    containerStyle = {},
    gestureHandling = 'cooperative',
    selected = false,
    selectedCompany,
  } = props;
  const refMap = useRef<any>(null);
  const mapContainerStyle = {
    width: size ? `${size}px` : '100%',
    height: size ? `${size}px` : '100%',
  };

  const [center, setCenter] = useState<LatLang>(defaultPosition);
  const [showModal, setShowModal] = useState(false);
  const [companyName, setCompanyName] = useState('');

  const handleBoundsChanged = () => {
    if (refMap && refMap.current) {
      const { lat, lng } = refMap.current.state.map.center;
      setCenter({
        lat: lat(),
        lng: lng(),
      });
      if (onMarkerChange) {
        onMarkerChange({ lat: lat(), lng: lng() });
      }
    }
  };

  return (
    <div className="flbx-google-maps-container" style={containerStyle}>
      <GoogleMapComponent
        ref={refMap}
        mapContainerStyle={mapContainerStyle}
        center={center}
        onDragEnd={useCallback(handleBoundsChanged, [refMap])}
        onZoomChanged={useCallback(handleBoundsChanged, [refMap])}
        mapContainerClassName="community-map"
        options={{ mapId: googleMapId, gestureHandling }}
        zoom={zoom}
      >
        {companyProfiles.length ? companyProfiles.map((company) => (
          <>
            <Marker
              position={company.geoLocation}
              onClick={() => onMarkerClick && onMarkerClick(company)}
              onMouseOver={() => {
                setShowModal(true);
                setCompanyName(company.companyName);
              }}
              onMouseOut={() => {
                setShowModal(false);
                setCompanyName('');
              }}
              icon={selected && selectedCompany && selectedCompany.companyName === company.companyName ? '/selected_marker.png' : 'marker.png'}
            />
            {showModal && companyName === company.companyName && !onMarkerChange && (
              <InfoWindow position={company.geoLocation} options={{ pixelOffset: { width: 0, height: -15, equals: () => (true) } }}>
                <div>{companyName}</div>
              </InfoWindow>
            )}
          </>
        )) : null}
        <KmlLayer
          url={`https://raw.githubusercontent.com/balazskaxd/kml-service/main/nagytarcsa.kml?v=${mapTimestamp}`}
          onLoad={() => null}
          onUnmount={() => null}
          options={{ clickable: false, preserveViewport: true }}
        />
        {onMarkerChange && (
          <FontAwesomeIcon icon={faMapPin} className="map-pin" size="3x" color="#6645e0" />
        )}
      </GoogleMapComponent>
    </div>
  );
};

export default GoogleMap;
