import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Menu, Space, Table } from 'antd';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import MainLayout, { MenuType } from 'layouts/MainLayout/MainLayout';
import { getCompanyList } from 'actions/companiesActions';
import { getUsers, resendInvite, resetPassword } from 'actions/userActions';
import { RootState } from 'reducers/rootReducer';
import NavigationRoute from 'routes/NavigationRoute';
import './users-page.scss';

export interface Column {
  title: string;
  dataIndex?: string;
  key: string;
  fixed?: 'left';
  render?: (text: string, row: any) => any;
}

const UsersPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCompanyList());
    dispatch(getUsers());
  }, []);

  const { companyList } = useSelector(({ companies }: RootState) => companies);
  const { users } = useSelector(({ user }: RootState) => user);

  const handleSelectMenuItem = (key: string, userId: string) => {
    switch (key) {
      case 'resend': {
        dispatch(resendInvite(userId));
        break;
      }

      case 'reset': {
        dispatch(resetPassword(userId));
        break;
      }

      default:
    }
  };

  const listTableColumns: Column[] = [
    {
      title: 'Név',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Email hitelesítve',
      dataIndex: 'email_verified',
      key: 'email_verified',
    },
    // {
    //   title: 'Felhasználó szerepköre',
    //   dataIndex: 'userRole',
    //   key: 'userRole',
    // },
    {
      title: 'Cég',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Műveletek',
      key: 'actions',
      render: (_, record) => (
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu
              onClick={({ key }: { key: string }) => {
                handleSelectMenuItem(key, record.userId);
              }}
              items={[
                {
                  label: 'Regisztráció újraküldése',
                  key: 'resend',
                  disabled: record.email_verified === 'Igen',
                },
                {
                  label: 'Jelszó helyreállítása',
                  key: 'reset',
                },
              ]}
            />
          )}
        >
          <Space style={{ cursor: 'pointer' }}>
            <span>kezelés</span>
            <FontAwesomeIcon
              icon={faCaretDown}
            />
          </Space>
        </Dropdown>
      ),
    },
  ];

  const dataSource = users ? Object.keys(users).map((userKey) => ({
    userId: userKey,
    name: `${users[userKey].family_name} ${users[userKey].given_name}`,
    email: users[userKey].email,
    email_verified: users[userKey].email_verified === 'true' ? 'Igen' : 'Nem',
    userRole: users[userKey].userRole,
    company: companyList.find((item) => item._id === users[userKey].companyId)?.companyName || '',
  })) : [];

  return (
    <MainLayout
      selectedMenu={MenuType.USERS}
      leftPanelClassName="flbx-custom-left-panel"
      menuClassName="flbx-custom-menu"
    >
      <div className="post-container no-max-width">
        <div className="flbx-table-header-container">
          <div className="create-post-header-title">Felhasználók listája</div>
          <Button
            type="primary"
            label="FELHASZNÁLÓ LÉTREHOZÁSA"
            onClick={() => navigate(NavigationRoute.CreateUser)}
          />
        </div>
        <Table
          className="users-table"
          columns={listTableColumns}
          dataSource={dataSource}
          showSorterTooltip={false}
          pagination={false}
          scroll={{ x: 800 }}

        />
      </div>
    </MainLayout>
  );
};

export default UsersPage;
