import React, { FC } from 'react';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullhorn,
  // faPoll,
  faBuilding,
  faMapMarkedAlt,
  faSignOutAlt,
  faUser,
  faTags,
} from '@fortawesome/pro-regular-svg-icons';
import { MenuType } from 'layouts/MainLayout/MainLayout';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import './main-nav.scss';

export interface MainNavProps {
  selectedMenu: MenuType | string;
  menuClickHandler: (key: string) => void,
}

const MainNav: FC<MainNavProps> = ({
  selectedMenu,
  menuClickHandler,
}) => {
  const { isAdmin } = useSelector(({ user }: RootState) => user);
  const menuItems = [
    { menuKey: MenuType.POSTS, icon: faBullhorn, label: 'Hírek' },
    // { menuKey: MenuType.VOTES, icon: faPoll, label: 'Szavazások' },
    { menuKey: MenuType.COMPANIES, icon: faBuilding, label: 'Céglista' },
    { menuKey: MenuType.COMPANYMAP, icon: faMapMarkedAlt, label: 'Cégtérkép' },
    { menuKey: MenuType.PRODUCT_COMPANIES, icon: faBuilding, label: 'Termékkereső' },
  ];

  const privateMenuItems = [
    { menuKey: MenuType.POSTS, icon: faBullhorn, label: 'Hírek' },
    // { menuKey: MenuType.VOTES, icon: faPoll, label: 'Szavazások' },
    { menuKey: MenuType.COMPANIES, icon: faBuilding, label: 'Céglista' },
    { menuKey: MenuType.COMPANYMAP, icon: faMapMarkedAlt, label: 'Cégtérkép' },
    { menuKey: MenuType.USERS, icon: faUser, label: 'Felhasználók' },
    { menuKey: MenuType.PRODUCT_COMPANIES, icon: faBuilding, label: 'Termékkereső' },
    { menuKey: MenuType.PRODUCT_PORTFOLIOS, icon: faTags, label: 'Termék portfólió' },
  ];

  const menu = isAdmin ? privateMenuItems : menuItems;
  return (
    <div className="main-nav-container">
      <Menu
        className="main-nav"
        selectedKeys={[selectedMenu]}
        onClick={({ key }) => menuClickHandler(key)}
      >
        {
          menu.map(({ menuKey, icon, label }) => (
            <Menu.Item
              key={menuKey}
              icon={(
                <FontAwesomeIcon icon={icon} />
              )}
            >
              {label}
            </Menu.Item>
          ))
        }
      </Menu>
      <Menu
        className="main-nav-extra"
        onClick={({ key }) => menuClickHandler(key)}
      >
        <Menu.Item
          key={MenuType.LOGOUT}
          icon={(
            <FontAwesomeIcon icon={faSignOutAlt} />
          )}
        >
          Kijelentkezés
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MainNav;
