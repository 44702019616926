import React, { FC } from 'react';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import './tabview.scss';

export interface Tab {
  title: string;
  id: string;
}

export interface TabViewProps {
  title: string;
  buttonTitle: string;
  loading: boolean;
  onClick: () => void;
  tabs: Tab[];
  selectedTab: Tab;
  onTabChange: (tab: Tab) => void;
  content: React.ReactNode;
  disabled: boolean;
}

const TabView: FC<TabViewProps> = ({ title, buttonTitle, onClick, tabs, selectedTab, onTabChange, content, loading, disabled }) => (
  <div className="tabview-container">
    <div className="tabview-header-container">
      <div className="tabview-title">{title}</div>
      <Button label={buttonTitle} onClick={onClick} type="primary" loading={loading} disabled={disabled} />
    </div>
    <div className="tabview-content-container">
      <div className="tabview-tabs-container">
        {tabs.map((item) => (
          <div
            className={`tabview-tab${item.id === selectedTab.id ? ' active' : ''}`}
            tabIndex={0}
            onClick={() => onTabChange(item)}
            role="button"
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className="tabview-content">{content}</div>
    </div>
  </div>
);

export default TabView;
