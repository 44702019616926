import { takeEvery, call } from 'redux-saga/effects';
import i18next from 'i18next';
import {
  APP_CHANGE_LANGUAGE, AppChangeLanguageType,
} from 'consts/appActionTypes';

export function* changeLanguage(action: AppChangeLanguageType) {
  yield call(i18next.changeLanguage, action.payload.lang);
}

export const appSagas = [
  takeEvery(APP_CHANGE_LANGUAGE, changeLanguage),
];
