import { combineReducers } from 'redux';
import app from 'reducers/app/appReducer';
import user from 'reducers/user/userReducer';
import community from 'reducers/community/communityReducer';
import companies from 'reducers/companies/companyReducer';

const rootReducer = combineReducers({
  app,
  user,
  community,
  companies,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
