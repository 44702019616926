/* eslint-disable */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import InputBlock from 'components/InputBlock/InputBlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { ProductPortfolioDto } from 'reducers/companies/types';
import { useDispatch, useSelector } from 'react-redux';
import { getProductPortfolioList } from 'actions/companiesActions';
import { RootState } from 'reducers/rootReducer';
import { debounce } from 'lodash';

export interface ProductsBlockProps {
  data: ProductPortfolioDto[];
  onSelectPortfolio: (productPortfolio: ProductPortfolioDto) => void;
  onDeselectPortfolio: (id: string) => void;
  fixHeight?: boolean;
}

const ProductsBlock: FC<ProductsBlockProps> = (props) => {
  const { data, onSelectPortfolio, onDeselectPortfolio, fixHeight = true } = props;
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const {
    productPortfolioList,
  } = useSelector(({ companies }: RootState) => companies);

  const onSelect = (value: ProductPortfolioDto) => {
    onSelectPortfolio(value);
    setSearchValue('');
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const search = useCallback(debounce((value) => dispatch(getProductPortfolioList(value)), 300), []);

  useEffect(() => {
    if (searchValue && searchValue.length >= 3) {
      search(searchValue);
    }
  }, [searchValue]);

  const selectedItem = (value: string) => productPortfolioList.find((item) => item._id === value) as ProductPortfolioDto;
  return (
    <InputBlock title="Termékek" fixHeight={fixHeight}>
      <Select
        showSearch
        style={{ width: '100%' }}
        onSearch={onSearch}
        notFoundContent={null}
        filterOption={false}
        searchValue={searchValue}
        placeholder="Keress a portfóliókban"
        dropdownClassName="product-block-dropdown"
        onSelect={(value: any) => { onSelect(selectedItem(value)); }}
      >
        {productPortfolioList && productPortfolioList.map((item) => (
          <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>
        ))}
      </Select>
      <div className="product-portfolio-tag-container">
        {data ? data.map((item) => (
          <div
            key={item.name}
            className="product-portfolio-tag"
          >
            {item.name}
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => onDeselectPortfolio(item._id || '')}
              size="1x"
              color="black"
              className="remove-tag"
            />
          </div>
        )) : null}
      </div>
    </InputBlock>
  );
};

export default ProductsBlock;
