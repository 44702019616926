import { Answer, CreatePoll } from 'reducers/community/types';
import {
  GET_POSTS_R, GetPostsRequestedType,
  GET_POLLS_R, GetPollsRequestedType,
  CREATE_POSTS_R, CreatePostRequestedType,
  CREATE_POLLS_R, CreatePollRequestedType,
  ANSWER_POLL_R, AnswerPollRequestedType,
} from 'consts/communityActionTypes';

export const getPosts = (): GetPostsRequestedType => ({
  type: GET_POSTS_R,
});

export const createPost = (content: string): CreatePostRequestedType => ({
  type: CREATE_POSTS_R,
  payload: {
    content,
  },
});

export const getPolls = (): GetPollsRequestedType => ({
  type: GET_POLLS_R,
});

export const createPoll = (poll: CreatePoll): CreatePollRequestedType => ({
  type: CREATE_POLLS_R,
  payload: {
    poll,
  },
});

export const answerPoll = (answeredId: string, answers: Answer[], userId: string): AnswerPollRequestedType => ({
  type: ANSWER_POLL_R,
  payload: {
    answeredId,
    answers,
    userId,
  },
});
