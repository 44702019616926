import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import PersonalContact from 'components/PersonalContact/PersonalContact';
import { PersonalContact as PersonalContactType } from 'reducers/companies/types';
import InputBlock from 'components/InputBlock/InputBlock';

interface PersonalContactsBlockProps {
  contacts: PersonalContactType[];
  handleAddContact: () => void;
  handleContactChange: (selectedContactIndex: number, field: string, value: string) => void;
  handleDeleteContact: (index: number) => void;
  fixHeight?: boolean;
}

const PersonalContactsBlock: FC<PersonalContactsBlockProps> = ({
  contacts,
  handleAddContact,
  handleDeleteContact,
  handleContactChange,
  fixHeight = true,
}) => {
  const { t } = useTranslation();

  return (
    <InputBlock title="Kapcsolattartók" fixHeight={fixHeight}>
      {
        contacts.map((contact, index) => (
          <PersonalContact
            key={`contact-${index.toString()}`}
            index={index}
            contact={contact}
            handleDeleteContact={handleDeleteContact}
            handleContactChange={handleContactChange}
          />
        ))
      }
      <Button
        label={t('pages.companies.addContact') as string}
        type="ghost"
        onClick={handleAddContact}
      />
    </InputBlock>
  );
};

export default PersonalContactsBlock;
