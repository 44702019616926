import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { faUser, faKey, faUserSecret } from '@fortawesome/pro-duotone-svg-icons';
import { useNavigate } from 'react-router-dom';
import { IconInput } from '@flexboxapps/flbx-webapp-ui';
import { verifyUser } from 'actions/userActions';
import { RootState } from 'reducers/rootReducer';
import NavigationRoute from 'routes/NavigationRoute';

export interface VerifyFormProps {
  queryEmail: string;
  queryToken: string;
}

const VerifyForm: FC<VerifyFormProps> = ({
  queryEmail,
  queryToken,
}) => {
  const [email, setEmail] = useState(queryEmail);
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(queryToken);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verifyLoading, verifySucceeded } = useSelector(({ user }: RootState) => user);

  useEffect(() => {
    if (verifySucceeded) {
      setEmail('');
      setPassword('');
      setToken('');
      navigate(NavigationRoute.Login);
    }
  }, [verifySucceeded]);

  const handleVerify = () => {
    if (email && password && token) {
      dispatch(verifyUser({ email, password, token }));
    }
  };

  return (
    <div className="login-container">
      <div className="input-container">
        <IconInput
          value={token}
          placeholder="Token"
          iconName={faUserSecret}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setToken(e.target.value);
          }}
          type="password"
        />
      </div>
      <div className="input-container">
        <IconInput
          value={email}
          placeholder={t('pages.login.inputs.email')}
          iconName={faUser}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div className="input-container">
        <IconInput
          value={password}
          placeholder={t('pages.login.inputs.password')}
          iconName={faKey}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
          }}
          type="password"
        />
      </div>
      <div className="login-button-container">
        <Button
          onClick={handleVerify}
          type="primary"
          size="large"
          loading={verifyLoading}
          disabled={!email || !token || !password}
        >
          Regisztráció véglegesítése
        </Button>
      </div>
      <div
        className="flbx-link verify-registration"
        role="button"
        tabIndex={0}
        onClick={() => navigate(NavigationRoute.Login)}
      >
        visszatérés a bejelentkezéshez
      </div>
    </div>
  );
};

export default VerifyForm;
