import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { editProductPortfolio, getProductPortfolioList } from 'actions/companiesActions';
import { Input, Button } from '@flexboxapps/flbx-webapp-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { RootState } from 'reducers/rootReducer';
import { ProductPortfolioDto } from 'reducers/companies/types';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader/Loader';
import './new-product-portfolio-page.scss';

const EditProductPortfolioPage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const editedProductPortfolioId = params.id;

  const [data, setData] = useState<ProductPortfolioDto>();
  const [keyword, setKeyword] = useState('');

  const { productPortfolioSucceeded, productPortfolioList } = useSelector(({ companies }: RootState) => companies);

  useEffect(() => {
    if (!productPortfolioList.length) {
      dispatch(getProductPortfolioList(''));
    }
  }, []);

  useEffect(() => {
    const productPortfolio = productPortfolioList.find((item) => item._id === editedProductPortfolioId);
    setData(productPortfolio);
  }, [productPortfolioList]);

  useEffect(() => {
    if (productPortfolioSucceeded) {
      notification.success({
        message: 'Termék módosítása sikeres!',
      });
    }
  }, [productPortfolioSucceeded]);

  const onChange = (key: keyof ProductPortfolioDto, value: string | string[]) => {
    if (data) {
      setData({
        ...data,
        [key]: value,
      });
    }
  };

  const isCreateDisabled = useMemo(() => data?.name === '' || !data?.keywords.length, [data]);

  const handleSubmit = () => {
    if (!isCreateDisabled) {
      dispatch(editProductPortfolio(data?._id || '', data?.name || '', data?.keywords || []));
    }
  };

  const addKeyword = () => {
    onChange('keywords', data ? data.keywords.concat(keyword) : []);
  };

  const deleteKeyword = (index: number) => {
    onChange('keywords', data ? data.keywords.filter((_, keywordIndex) => keywordIndex !== index) : []);
  };
  return (
    <MainLayout>
      <div className="post-container">
        <div className="create-post-header-title">Termék módosítása</div>
        <Loader isLoading={!productPortfolioList}>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Input
                label="Megnevezés"
                placeholder="Megnevezés"
                value={data?.name || ''}
                onChange={(e) => onChange('name', e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Input
                label="Kulcsszó"
                placeholder="Kulcsszó hozzáadása"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onPressEnter={() => {
                  addKeyword();
                  setKeyword('');
                }}
              />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <h3 className="create-post-header-subtitle">Kulcsszavak</h3>
            </Col>
            <div className="product-portfolio-tag-container" style={{ marginLeft: '12px' }}>
              {data?.keywords ? data.keywords.map((item, index) => (
                <div className="product-portfolio-tag">
                  {item}
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    onClick={() => deleteKeyword(index)}
                    size="1x"
                    color="black"
                    className="remove-tag"
                  />
                </div>
              )) : null}
            </div>
          </Row>
        </Loader>
        <Button
          type="primary"
          label="Termék módosítása"
          disabled={isCreateDisabled}
          onClick={handleSubmit}
        />
      </div>
    </MainLayout>
  );
};

export default EditProductPortfolioPage;
