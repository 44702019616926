import React, { FC, PropsWithChildren } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import './loader.scss';

export interface LoaderProps {
  isLoading: boolean;
  pageLoader?: boolean;
  style?: React.CSSProperties;
}

const Loader: FC<PropsWithChildren<LoaderProps>> = ({
  isLoading,
  children,
  style = {},
  pageLoader = false,
}) => (
  <div
    className={`loader${pageLoader ? ' page-loader' : ''}`}
    style={style}
  >
    {
      isLoading
        ? (
          <div className="loader-spinner">
            <PuffLoader size={60} color="#6645e0" />
          </div>
        )
        : children
    }
  </div>
);

export default Loader;
