import {
  USER_LOGIN_R, UserLoginRequestedType,
  RESTORE_USER_BY_TOKEN_R, RestoreUserByTokenRequestedType,
  USER_LOGOUT_R, UserLogoutRequestedType,
  CREATE_USER_R, CreateUserRequestedType,
  VERIFY_USER_R, VerifyUserRequestedType,
  GET_USERS_R, GetUsersRequestedType,
  RESEND_INVITE_R, ResendInviteRequestedType,
  USER_FORGOT_PASSWORD_R, UserForgotPasswordRequestedType,
  USER_SEND_EMAIL_R, UserSendEmailRequestedType, ResetPasswordRequestedType, RESET_PASSWORD_R,
} from 'consts/userActionTypes';

export const login = (email: string, password: string, next: string): UserLoginRequestedType => ({
  type: USER_LOGIN_R,
  payload: {
    email,
    password,
    next,
  },
});

export const restoreUserByToken = (next?: string): RestoreUserByTokenRequestedType => ({
  type: RESTORE_USER_BY_TOKEN_R,
  payload: {
    next,
  },
});

export const logout = (): UserLogoutRequestedType => ({
  type: USER_LOGOUT_R,
});

export const createUser = (data: {
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  companyId: string;
}): CreateUserRequestedType => ({
  type: CREATE_USER_R,
  payload: {
    data,
  },
});

export const verifyUser = (data: { email: string; password: string; token: string; }): VerifyUserRequestedType => ({
  type: VERIFY_USER_R,
  payload: {
    data,
  },
});

export const getUsers = (id?: string): GetUsersRequestedType => ({
  type: GET_USERS_R,
  payload: {
    id,
  },
});

export const resendInvite = (userId: string): ResendInviteRequestedType => ({
  type: RESEND_INVITE_R,
  payload: {
    userId,
  },
});

export const resetPassword = (userId: string): ResetPasswordRequestedType => ({
  type: RESET_PASSWORD_R,
  payload: {
    userId,
  },
});

export const forgotPassword = (password: string, token: string, email: string): UserForgotPasswordRequestedType => ({
  type: USER_FORGOT_PASSWORD_R,
  payload: {
    password,
    token,
    email,
  },
});

export const sendEmail = (email: string): UserSendEmailRequestedType => ({
  type: USER_SEND_EMAIL_R,
  payload: {
    email,
  },
});
