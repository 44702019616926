import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import Answer from 'components/Answer/Answer';
import { Answer as AnswerProps, Poll } from 'reducers/community/types';
import './poll.scss';

export interface PollProps {
  poll: Poll;
  onAnswer: (id: string, answers: AnswerProps[]) => void;
  allAnswers: number;
}

const PollComponent: React.FC<PollProps> = (props) => {
  const { poll, onAnswer, allAnswers } = props;
  const { userId } = useSelector(({ user }: RootState) => user);
  return (
    <div className="poll-container">
      <div className="poll-question-container">{poll.question}</div>
      <div className="poll-answers-container">
        {poll.options.map((answerItem) => (
          <Answer
            answered={answerItem.userIds.includes(userId)}
            answer={answerItem.title}
            percentage={!allAnswers ? 0 : (answerItem.userIds.length / allAnswers) * 100}
            onAnswer={() => onAnswer(answerItem._id, poll.options)}
          />
        ))}
      </div>
    </div>
  );
};

export default PollComponent;
