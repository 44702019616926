import React, { FC } from 'react';
import InputBlock from 'components/InputBlock/InputBlock';
import ImageUpload from 'components/ImageUpload/ImageUpload';

interface CompanyImageUploadBlockProps {
  title: string;
  type: string;
  fieldName: string;
  imageSize: number;
  onImageChange: (value: string, id: string) => void;
  imageSource?: string | null;
}

const CompanyImageUploadBlock: FC<CompanyImageUploadBlockProps> = ({
  title,
  type,
  fieldName,
  imageSize,
  onImageChange,
  imageSource,
}) => (
  <InputBlock title={title}>
    <ImageUpload
      type={type}
      fieldName={fieldName}
      imageSize={imageSize}
      onImageChange={onImageChange}
      imageSource={imageSource}
    />
  </InputBlock>
);

export default CompanyImageUploadBlock;
