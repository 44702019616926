import LoginPage from 'routes/LoginPage/LoginPage';
import MainPage from 'routes/MainPage/MainPage';
import VotePage from 'routes/VotePage/VotePage';
import CompaniesPage from 'routes/CompaniesPage/CompaniesPage';
import CompanyMapPage from 'routes/CompanyMapPage/CompanyMapPage';
import CreateCompanyPage from 'routes/CompaniesPage/CreateCompanyPage';
import ProductCompaniesPage from 'routes/CompaniesPage/ProductCompaniesPage';
import NewUserPage from 'routes/NewUserPage/NewUserPage';
import VerifyUserPage from 'routes/VerifyUserPage/VerifyUserPage';
import EditCompanyPage from 'routes/CompaniesPage/EditCompanyPage';
import UsersPage from 'routes/UsersPage/UsersPage';
import ForgotPasswordPage from 'routes/ForgotPasswordPage/ForgotPasswordPage';
import RestorePasswordPage from 'routes/RestorePasswordPage/RestorePasswordPage';
import ProductPortfoliosPage from 'routes/ProductPortfoliosPage/ProductPortfoliosPage';
import NewProductPortfolioPage from 'routes/NewProductPortfolioPage/NewProductPortfolioPage';
import EditProductPortfolioPage from 'routes/NewProductPortfolioPage/EditProductPortfolioPage';

const Routes = {
  LoginPage,
  MainPage,
  VotePage,
  CompaniesPage,
  CompanyMapPage,
  CreateCompanyPage,
  NewUserPage,
  VerifyUserPage,
  EditCompanyPage,
  UsersPage,
  ForgotPasswordPage,
  RestorePasswordPage,
  ProductCompaniesPage,
  ProductPortfoliosPage,
  NewProductPortfolioPage,
  EditProductPortfolioPage,
};

export default Routes;
