export default {
  translation: {
    app: {
      common: {
        buttons: {
          back: 'Vissza',
          restart: 'Újrakezdés',
          reset: 'Visszaállítás',
          yes: 'Igen',
          no: 'Nem',
          add: 'Hozzáadás',
          cancel: 'Mégsem',
          close: 'Bezár',
          edit: 'Szerkesztés',
          create: 'Létrehozás',
          save: 'Mentés',
        },
        colors: {
          green: 'zöld',
          blue: 'kék',
          purple: 'lila',
          yellow: 'sárga',
          orange: 'narancs',
          red: 'piros',
        },
      },
      languages: {
        hu: 'magyar',
        en: 'angol',
      },
    },
    pages: {
      update: {
        availableUpdate: 'Elérhető egy új frissítés!',
        updateApp: 'App frissítése',
      },
      login: {
        title: 'Bejelentkezés',
        subtitle: 'Adja meg a bejelentkezési adatait.',
        inputs: {
          email: 'Email cím',
          password: 'Jelszó',
          login: 'Bejelentkezés',
          code: 'Kód',
        },
        errors: {
          emailError: 'Hibás email cím',
          badCredentials: 'Hibás email cím vagy jelszó',
          emptyEmail: 'Bejelentkezéshez használt email cím:',
          emptyPassword: 'Fiókhoz tartozó jelszó:',
        },
      },
      dashboard: {
        title: 'Üdvözöljük',
        infocards: {
          categories: 'Kategóriák',
          products: 'Termékek',
        },
      },
      categories: {
        title: 'Kategóriák \nés termékek',
        labels: {
          titles: {
            hu: 'név',
            en: 'angol név',
          },
        },
        inputs: {
          titles: {
            hu: 'magyar nyelvű elnevezés',
            en: 'angol nyelvű elnevezés',
          },
          parentCategory: 'szülő kategória',
          colorCode: 'színkód',
        },
      },
      products: {
        buttons: {
          addProductToCategory: 'új termék hozzáadása\na kategóriához',
        },
        labels: {
          titles: {
            hu: 'név',
            en: 'angol név',
          },
          printTitles: {
            hu: 'nyomtatási elnevezés',
            en: 'angol nyomtatási elnevezés',
          },
          mainCategories: 'Kategóriák',
        },
        inputs: {
          titles: {
            hu: 'termék elnevezése',
            en: 'termék elnevezése',
          },
          printTitles: {
            hu: 'nyomtatási név',
            en: 'nyomtatási név',
          },
          selectedCategory: 'kategória',
          price: 'ár',
        },
      },
      sales: {
        title: 'Eladások',
        emptyPage: {
          title: 'Nincs megjeleníthető eladási tétel',
          loading: 'Eladási adatok betöltése...',
        },
      },
      companies: {
        addContact: 'kapcsolattartó hozzáadása',
        companyServices: 'Szolgáltatások',
      },
      retorePassword: {
        title: 'Jelszó visszaállítás',
        subtitle: 'Adja meg új jelszavát!',
        inputs: {
          password: 'Jelszó',
          button: 'Jelszó visszaállítása',
        },
        success: {
          emailMessage: 'Email küldés sikerült!',
          emailDescription: 'Az email-ben kapott kódot felhasználva új jelszót hozhat létre!',
          forgotPasswordMessage: 'A jelszó helyreállítása megtörtént.',
          forgotPasswordDescription: 'Jelentkezzen be új jelszavával!',
        },
        errors: {
          emptyPassword: 'A mező kitöltése kötelező!',
          notEqualPassword: 'A két jelszó nem egyezik meg!',
          wrongPassword: 'A jelszónak minimum 8 karakternek kell lennie és tartalmaznia kell kis és nagybetűt illetve számot is!',
          emailSentErrorMessage: 'Email küldési hiba!',
          emailSentErrorDescription: 'Az email küldés nem sikerült!',
        },
      },
    },
    drawers: {
      addCategory: {
        title: 'Új kategória létrehozása',
        subtitle: 'Adja meg az elnevezéseket',
      },
    },
  },
};
