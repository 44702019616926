import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Menu, Space, Table, Modal } from 'antd';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import MainLayout, { MenuType } from 'layouts/MainLayout/MainLayout';
import { getProductPortfolioList, deleteProductPortfolio } from 'actions/companiesActions';
import { RootState } from 'reducers/rootReducer';
import NavigationRoute from 'routes/NavigationRoute';
import './product-portfolios-page.scss';

export interface Column {
  title: string;
  dataIndex?: string;
  key: string;
  fixed?: 'left' | 'right';
  width?: number | string;
  render?: (text: string, row: any) => any;
}

const ProductPortfoliosPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const { productPortfolioList, productPortfolioLoading, productPortfolioSucceeded } = useSelector(({ companies }: RootState) => companies);

  const handleCancel = () => {
    setOpen(false);
    setSelectedId('');
  };

  useEffect(() => {
    dispatch(getProductPortfolioList(''));
  }, []);

  useEffect(() => {
    if (!productPortfolioLoading && productPortfolioSucceeded) {
      handleCancel();
    }
  }, [productPortfolioLoading, productPortfolioSucceeded]);

  const handleOk = () => {
    dispatch(deleteProductPortfolio(selectedId));
  };

  const handleSelectMenuItem = (key: string, id: string) => {
    if (key === 'edit') {
      navigate(`/product-portfolio/edit/${id}`);
    }
    if (key === 'delete') {
      setSelectedId(id);
      setOpen(true);
    }
  };

  const listTableColumns: Column[] = [
    {
      title: 'Termék neve',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '25%',
    },
    {
      title: 'Kulcsszavak',
      dataIndex: 'keywords',
      key: 'keywords',
      width: '50%',
    },
    {
      title: 'Műveletek',
      key: 'actions',
      width: '25%',
      render: (_, record) => (
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu
              onClick={({ key }: { key: string }) => {
                handleSelectMenuItem(key, record.id);
              }}
              items={[
                {
                  label: 'Szerkesztés',
                  key: 'edit',
                },
                {
                  label: 'Törlés',
                  key: 'delete',
                },
              ]}
            />
          )}
        >
          <Space style={{ cursor: 'pointer' }}>
            <span>kezelés</span>
            <FontAwesomeIcon
              icon={faCaretDown}
            />
          </Space>
        </Dropdown>
      ),
    },
  ];

  const dataSource = productPortfolioList ? productPortfolioList.map((listItem) => ({
    id: listItem._id,
    name: listItem.name,
    keywords: listItem.keywords.toString(),
  })).filter((item) => item.keywords.toLowerCase().includes(filterValue.toLocaleLowerCase()) || item.name.toLowerCase().includes(filterValue.toLowerCase()))
    : [];

  return (
    <MainLayout
      selectedMenu={MenuType.PRODUCT_PORTFOLIOS}
      leftPanelClassName="flbx-custom-left-panel"
      menuClassName="flbx-custom-menu"
    >
      <div className="post-container no-max-width">
        <div className="flbx-table-header-container">
          <div className="flbx-table-header-inner-container">
            <div className="create-post-header-title">Termék portfólió lista</div>
            <Button
              type="primary"
              label="TERMÉK LÉTREHOZÁSA"
              onClick={() => navigate(NavigationRoute.NewProductPortfolio)}
            />
          </div>
          <input
            placeholder="Kezdjen el gépelni a kereséshez"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            className="company-list-fiter-input"
          />
        </div>
        <Table
          className="users-table"
          columns={listTableColumns}
          dataSource={dataSource}
          showSorterTooltip={false}
          pagination={false}
          scroll={{ x: 800 }}

        />
      </div>
      <Modal
        open={open}
        title="Törlés"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button type="ghost" onClick={handleCancel} label="Mégsem" />,
          <Button type="primary" loading={productPortfolioLoading} onClick={handleOk} label="Törlés" />,
        ]}
      >
        <p className="delete-modal-title">Biztosan törlöd ezt az elemet?</p>
      </Modal>

    </MainLayout>
  );
};

export default ProductPortfoliosPage;
