import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { Input } from '@flexboxapps/flbx-webapp-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { CompanyContact as CompanyContactType } from 'reducers/companies/types';
import './company-contact.scss';

interface CompanyContactProps {
  index: number;
  contact: CompanyContactType;
  handleContactChange: (selectedContactIndex: number, field: string, value: string) => void;
  handleDeleteContact: (index: number) => void;
}

const CompanyContact: FC<CompanyContactProps> = ({
  index,
  contact,
  handleContactChange,
  handleDeleteContact,
}) => (
  <div className="company-contact">
    <div className="company-contact-body">
      <Row gutter={{ xs: 8, sm: 16 }}>
        <Col span={24}>
          <Input
            label="megnvezés"
            value={contact.label}
            placeholder=""
            onChange={(e) => {
              handleContactChange(index, 'label', e.target.value);
            }}
          />
        </Col>
        <Col span={24}>
          <Input
            label="email"
            value={contact.email}
            placeholder=""
            onChange={(e) => {
              handleContactChange(index, 'email', e.target.value);
            }}
          />
        </Col>
        <Col span={24}>
          <Input
            label="telefon"
            value={contact.phone}
            placeholder=""
            onChange={(e) => {
              handleContactChange(index, 'phone', e.target.value);
            }}
          />
        </Col>
      </Row>
    </div>
    <div className="company-contact-remove">
      <FontAwesomeIcon
        icon={faTrashAlt}
        onClick={() => handleDeleteContact(index)}
        size="2x"
        color="#E74C3C"
      />
    </div>
  </div>
);

export default CompanyContact;
