import { Post, Poll, CreatePoll, Answer } from 'reducers/community/types';

export const GET_POSTS_F = 'GET_POSTS_F';
export const GET_POSTS_R = 'GET_POSTS_R';
export const GET_POSTS_S = 'GET_POSTS_S';
export const CREATE_POSTS_F = 'CREATE_POSTS_F';
export const CREATE_POSTS_R = 'CREATE_POSTS_R';
export const CREATE_POSTS_S = 'CREATE_POSTS_S';

export const GET_POLLS_F = 'GET_POLLS_F';
export const GET_POLLS_R = 'GET_POLLS_R';
export const GET_POLLS_S = 'GET_POLLS_S';
export const CREATE_POLLS_F = 'CREATE_POLLS_F';
export const CREATE_POLLS_R = 'CREATE_POLLS_R';
export const CREATE_POLLS_S = 'CREATE_POLLS_S';
export const ANSWER_POLL_F = 'ANSWER_POLL_F';
export const ANSWER_POLL_R = 'ANSWER_POLL_R';
export const ANSWER_POLL_S = 'ANSWER_POLL_S';

export interface GetPostsFailedType {
  type: typeof GET_POSTS_F,
  payload: { errorMessage: string },
}

export interface GetPostsRequestedType {
  type: typeof GET_POSTS_R,
}

export interface GetPostsSucceededType {
  type: typeof GET_POSTS_S,
  payload: { posts: Post[] },
}

export interface CreatePostFailedType {
  type: typeof CREATE_POSTS_F,
  payload: { errorMessage: string },
}

export interface CreatePostRequestedType {
  type: typeof CREATE_POSTS_R,
  payload: {
    content: string,
  },
}

export interface CreatePostSucceededType {
  type: typeof CREATE_POSTS_S,
  payload: { posts: Post[] },
}

export interface GetPollsFailedType {
  type: typeof GET_POLLS_F,
  payload: { errorMessage: string },
}

export interface GetPollsRequestedType {
  type: typeof GET_POLLS_R,
}

export interface GetPollsSucceededType {
  type: typeof GET_POLLS_S,
  payload: { polls: Poll[] },
}

export interface CreatePollFailedType {
  type: typeof CREATE_POLLS_F,
  payload: { errorMessage: string },
}

export interface CreatePollRequestedType {
  type: typeof CREATE_POLLS_R,
  payload: {
    poll: CreatePoll,
  },
}

export interface CreatePollSucceededType {
  type: typeof CREATE_POLLS_S,
  payload: { polls: Poll[] },
}

export interface AnswerPollFailedType {
  type: typeof ANSWER_POLL_F,
  payload: { errorMessage: string },
}

export interface AnswerPollRequestedType {
  type: typeof ANSWER_POLL_R,
  payload: {
    answeredId: string;
    answers: Answer[];
    userId: string;
  };
}

export interface AnswerPollSucceededType {
  type: typeof ANSWER_POLL_S,
}

export type CommunityActionTypes =
  | GetPostsRequestedType
  | GetPostsSucceededType
  | GetPostsFailedType
  | GetPollsRequestedType
  | GetPollsSucceededType
  | GetPollsFailedType
  | CreatePostFailedType
  | CreatePostRequestedType
  | CreatePostSucceededType
  | CreatePollFailedType
  | CreatePollRequestedType
  | CreatePollSucceededType
  | AnswerPollFailedType
  | AnswerPollRequestedType
  | AnswerPollSucceededType;
