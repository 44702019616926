import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import { createPoll } from 'actions/communityActions';
import { RootState } from 'reducers/rootReducer';
import CreatePoll from './CreatePoll';

export interface CreatePollContainerProps {
  userName: string;
}

const CreatePollContainer: FC<CreatePollContainerProps> = ({ userName }) => {
  const dispatch = useDispatch();

  const initialState = {
    question: '',
    options: ['', '', ''],
  };

  const [values, setValues] = useState(initialState);
  const [isEditing, setIsEditing] = useState(false);

  const onAddAnswer = () => {
    setValues({
      ...values,
      options: values.options.concat(''),
    });
  };

  const onDeleteAnswer = (index: number) => {
    const options = values.options.filter((_, arrayIndex) => index !== arrayIndex);
    setValues({
      ...values,
      options,
    });
  };

  const onChange = (key: string, value: string, index?: number) => {
    if (index !== undefined) {
      const options = values.options.map((answerValue, arrayIndex) => (index === arrayIndex ? value : answerValue));
      setValues({
        ...values,
        options,
      });
    } else {
      setValues({
        ...values,
        [key]: value,
      });
    }
  };

  const onCancel = () => {
    setIsEditing(false);
    setValues(initialState);
  };

  const {
    community: { pollsCreating },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (!pollsCreating) {
      onCancel();
    }
  }, [pollsCreating]);

  return (
    <div className="create-post">
      <div className="create-post-header">
        <div className="create-post-header-text">
          <div className="create-post-header-title">{`Üdvözöljük, ${userName}!`}</div>
          <div className="create-post-header-subtitle">Készen állunk, vágjunk bele!</div>
        </div>
        {
          !isEditing
          && (
            <Button
              type="primary"
              label="új szavazás létrehozása"
              onClick={() => {
                setIsEditing(true);
              }}
            />
          )
        }
      </div>
      {
        isEditing
        && (
          <>
            <CreatePoll
              visible={isEditing}
              values={values}
              onAddAnswer={onAddAnswer}
              onDeleteAnswer={onDeleteAnswer}
              onChange={onChange}
            />
            <div className="main-page-button-group">
              <Button
                label="MÉGSEM"
                onClick={onCancel}
              />
              <Button
                type="primary"
                label="SZAVAZÁS LÉTREHOZÁSA"
                loading={pollsCreating}
                disabled={pollsCreating || !values.question || !values.options.every((item) => item !== '')}
                onClick={() => dispatch(createPoll(values))}
              />
            </div>
          </>
        )
      }
    </div>
  );
};

export default CreatePollContainer;
