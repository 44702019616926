import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint } from '@fortawesome/pro-regular-svg-icons';
import RestorePassword from 'components/RestorePassword/RestorePassword';
import { ReactComponent as LogoWideInverse } from 'assets/logo-wide-inverse.svg';
import { ReactComponent as LogoWide } from 'assets/logo-wide-normal.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { notification } from 'antd';
import './restore-password-page.scss';

export interface RestorePasswordPageProps {
  location?: {
    search: string;
  };
}

const RestorePasswordPage: FC<RestorePasswordPageProps> = (props) => {
  const { t } = useTranslation();
  const param = new URLSearchParams(props.location?.search);
  const token = param.get('token');
  const email = param.get('email');
  const { forgotPasswordSucceeded } = useSelector(({ user }: RootState) => user);

  useEffect(() => {
    if (forgotPasswordSucceeded) {
      notification.success({
        message: t('pages.retorePassword.success.forgotPasswordMessage') as string,
        description: t('pages.retorePassword.success.forgotPasswordDescription') as string,
      });
    }
  }, [forgotPasswordSucceeded]);

  return (
    <div className="page page-restore-password">
      <div className="logo">
        <LogoWide className="logo-normal" />
        <LogoWideInverse width="196" className="logo-inverse" />
      </div>
      <div className="restore-password-background">
        <div className="restore-password-box">
          <div className="header-container">
            <FontAwesomeIcon icon={faFingerprint} />
            <div>
              <div className="form-title">{t('pages.retorePassword.title') as string}</div>
              <div className="form-subtitle">{t('pages.retorePassword.subtitle') as string}</div>
            </div>
          </div>
          <RestorePassword emailParam={email || ''} tokenParam={token || ''} />
        </div>
      </div>
    </div>
  );
};

export default RestorePasswordPage;
