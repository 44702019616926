import React from 'react';
import { DropdownEntry } from 'reducers/companies/types';
import CompanyServiceSelect from 'components/CompanyServiceSelect/CompanyServiceSelect';
import InputBlock from 'components/InputBlock/InputBlock';
import { Alert } from 'antd';

interface CompanyServicesBlockProps {
  data: Array<DropdownEntry>;
  initialValue: Array<DropdownEntry>;
  onSearch?: (event: any) => void;
  onBlur?: (event: any) => void;
  onSelect?: (value: any, option: any) => void;
  onDeselect?: (value: string) => void;
  disabled?: boolean,
  fixHeight?: boolean;
}

function CompanyServicesBlock({
  data,
  initialValue,
  onSearch = () => { },
  onBlur = () => { },
  onSelect = () => { },
  onDeselect = () => { },
  disabled = false,
  fixHeight = true,
}: CompanyServicesBlockProps) {
  return (
    <InputBlock title="Szolgáltatások" fixHeight={fixHeight}>
      <CompanyServiceSelect
        onBlur={onBlur}
        onSearch={onSearch}
        onSelect={onSelect}
        onDeselect={onDeselect}
        initialValue={initialValue}
        disabled={disabled}
        data={data}
      />
      {
        disabled && (
          <Alert type="info" message="A szolgáltatás létrehozása vagy ellenőrzése folyamatban van" style={{ marginTop: 12 }} />
        )
      }
    </InputBlock>
  );
}

CompanyServicesBlock.defaultProps = {
  onSearch: () => { },
  onBlur: () => { },
  onSelect: () => { },
  onDeselect: () => { },
};

export default CompanyServicesBlock;
