import React, { FC } from 'react';
import InputBlock from 'components/InputBlock/InputBlock';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import { Company, LatLang } from 'reducers/companies/types';

interface CompanyLocationBlockProps {
  defaultPosition: LatLang;
  onMarkerChange: (value: string | object, field: string) => void;
  companyProfile?: Company;
}

const CompanyLocationBlock: FC<CompanyLocationBlockProps> = ({
  defaultPosition,
  onMarkerChange,
  companyProfile,
}) => (
  <InputBlock title="Lokáció">
    <GoogleMap
      containerStyle={{
        width: '100%',
        height: Math.min(600, window.innerHeight),
        left: 0,
        top: 64,
      }}
      defaultPosition={defaultPosition}
      companyProfiles={companyProfile ? [companyProfile] : []}
      onMarkerChange={(data) => onMarkerChange(data, 'geoLocation')}
    />
  </InputBlock>
);

export default CompanyLocationBlock;
