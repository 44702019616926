import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from 'components/App/Root';
import { LoadScript } from '@react-google-maps/api';
import { store } from 'store';
import { googleMapsApiKey, googleMapId } from 'services/config';
import reportWebVitals from './reportWebVitals';
import 'locales/i18n';
import '@flexboxapps/flbx-webapp-ui/dist/index.cjs.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <LoadScript
      googleMapsApiKey={googleMapsApiKey}
      mapIds={[googleMapId]}
    >
      <Root store={store} />
    </LoadScript>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
