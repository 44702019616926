/* eslint-disable */
import React, { FC, useEffect, useState, useRef } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faUser, faKey } from '@fortawesome/pro-duotone-svg-icons';
import { IconInput } from '@flexboxapps/flbx-webapp-ui';
import { login } from 'actions/userActions';
import { validateEmail } from 'services/services';
import { useNavigate } from 'react-router-dom';
import NavigationRoute from 'routes/NavigationRoute';
import './login.scss';

const LoginForm: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({
    emailError: false,
  });
  const navigate = useNavigate();
  const emailRef = useRef(email);
  const passwordRef = useRef(password);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { hasAuthenticatingError, isAuthenticating } = useSelector((store: any) => store.user);

  const setEmailError = (emailValue: string) => {
    if (validateEmail(emailValue)) {
      setError({ ...error, emailError: validateEmail(emailValue) });
      return true;
    }
    setError({ ...error, emailError: false });
    return null;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      dispatch(login(emailRef.current, passwordRef.current, NavigationRoute.Posts));
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleLogin = () => {
    if (email && password && !setEmailError(email)) {
      dispatch(login(email, password, NavigationRoute.Posts));
    }
  };

  return (
    <div className="login-container">
      <div className="input-container">
        {
          error.emailError && (
            <div className="error-message">
              {t('pages.login.errors.emailError') as string}
            </div>
          )
        }
        <IconInput
          value={email}
          placeholder={t('pages.login.inputs.email')}
          iconName={faUser}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (error.emailError) {
              setEmailError(e.target.value);
            }
            setEmail(e.target.value);
            emailRef.current = e.target.value;
          }}
          onBlur={() => { setEmailError(email); }}
          name="username"
        />
      </div>
      <div className="input-container">
        <IconInput
          value={password}
          placeholder={t('pages.login.inputs.password')}
          iconName={faKey}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
            passwordRef.current = e.target.value;
          }}
          type="password"
          name="password"
        />
      </div>
      <div
        className="flbx-link forgot-password"
        role="button"
        tabIndex={0}
        onClick={() => navigate(NavigationRoute.ForgotPassword)}
      >
        Elfelejtettem a jelszavam
      </div>
      <div
        className="flbx-link verify-registration"
        role="button"
        tabIndex={0}
        onClick={() => navigate(NavigationRoute.VerifyUser)}
      >
        Regisztráció véglegesítése
      </div>
      <div className="login-button-container">
        {
          hasAuthenticatingError && (
            <div className="error-message">
              {t('pages.login.errors.badCredentials') as string}
            </div>
          )
        }
        <Button
          size="large"
          onClick={handleLogin}
          type="primary"
          loading={isAuthenticating}
          disabled={error.emailError}
          style={{ width: '100%', borderRadius: 9, height: 48 }}
        >
          {t('pages.login.inputs.login') as string}
        </Button>
      </div>
    </div>
  );
};

export default LoginForm;
