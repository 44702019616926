import { Company, CompanyService, CompanyServiceDto, CreateCompany, DropdownEntry, ProductPortfolioDto } from 'reducers/companies/types';

export const COMPANIES_GET_COMPANY_LIST_R = 'COMPANIES_GET_COMPANY_LIST_R';
export const COMPANIES_GET_COMPANY_LIST_S = 'COMPANIES_GET_COMPANY_LIST_S';
export const COMPANIES_GET_COMPANY_LIST_F = 'COMPANIES_GET_COMPANY_LIST_F';

export interface GetCompanyListRequestedType {
  type: typeof COMPANIES_GET_COMPANY_LIST_R,
}

export interface GetCompanyListSucceededType {
  type: typeof COMPANIES_GET_COMPANY_LIST_S,
  payload: { companyList: Company[] },
}

export interface GetCompanyListFailedType {
  type: typeof COMPANIES_GET_COMPANY_LIST_F,
  payload: { companyFetchingError: string },
}

export const COMPANIES_CREATE_COMPANY_R = 'COMPANIES_CREATE_COMPANY_R';
export const COMPANIES_CREATE_COMPANY_S = 'COMPANIES_CREATE_COMPANY_S';
export const COMPANIES_CREATE_COMPANY_F = 'COMPANIES_CREATE_COMPANY_F';

export interface CreateCompanyRequestedType {
  type: typeof COMPANIES_CREATE_COMPANY_R,
  payload: {
    formData: CreateCompany;
  },
}

export interface CreateCompanySucceededType {
  type: typeof COMPANIES_CREATE_COMPANY_S,
}

export interface CreateCompanyFailedType {
  type: typeof COMPANIES_CREATE_COMPANY_F,
  payload: { companyFetchingError: string },
}

export const SET_CREATE_COMPANY_FIELD = 'SET_CREATE_COMPANY_FIELD';

export interface SetCreateCompanyField {
  type: typeof SET_CREATE_COMPANY_FIELD,
  payload: {
    field: string;
    value: string;
  },
}

export const GET_PRODUCT_PORTFOLIO_LIST_F = 'GET_PRODUCT_PORTFOLIO_LIST_F';
export const GET_PRODUCT_PORTFOLIO_LIST_R = 'GET_PRODUCT_PORTFOLIO_LIST_R';
export const GET_PRODUCT_PORTFOLIO_LIST_S = 'GET_PRODUCT_PORTFOLIO_LIST_S';

export interface GetProductPortfolioListRequestedType {
  type: typeof GET_PRODUCT_PORTFOLIO_LIST_R,
  payload: { name: string }
}

export interface GetProductPortfolioListSucceededType {
  type: typeof GET_PRODUCT_PORTFOLIO_LIST_S,
  payload: { productPortfolioList: ProductPortfolioDto[] },
}

export interface GetProductPortfolioListFailedType {
  type: typeof GET_PRODUCT_PORTFOLIO_LIST_F,
  payload: { companyFetchingError: string },
}

export const GET_COMPANY_SERVICE_LIST_F = 'GET_COMPANY_SERVICE_LIST_F';
export const GET_COMPANY_SERVICE_LIST_R = 'GET_COMPANY_SERVICE_LIST_R';
export const GET_COMPANY_SERVICE_LIST_S = 'GET_COMPANY_SERVICE_LIST_S';

export interface GetCompanyServiceListRequestedType {
  type: typeof GET_COMPANY_SERVICE_LIST_R,
  payload: { name: string }
}

export interface GetCompanyServiceListSucceededType {
  type: typeof GET_COMPANY_SERVICE_LIST_S,
  payload: { companyServiceList: DropdownEntry[] },
}

export interface GetCompanyServiceListFailedType {
  type: typeof GET_COMPANY_SERVICE_LIST_F,
  payload: { companyFetchingError: string },
}

export const CREATE_COMPANY_SERVICE_F = 'CREATE_COMPANY_SERVICE_F';
export const CREATE_COMPANY_SERVICE_R = 'CREATE_COMPANY_SERVICE_R';
export const CREATE_COMPANY_SERVICE_S = 'CREATE_COMPANY_SERVICE_S';

export interface CreateCompanyServiceRequestedType {
  type: typeof CREATE_COMPANY_SERVICE_R,
  payload: { companyService: CompanyServiceDto, type: string }
}

export interface CreateCompanyServiceSucceededType {
  type: typeof CREATE_COMPANY_SERVICE_S,
  payload: { companyService: CompanyService, type: string },
}

export interface CreateCompanyServiceFailedType {
  type: typeof CREATE_COMPANY_SERVICE_F,
  payload: { companyFetchingError: string },
}

export const CREATE_PRODUCT_PORTFOLIO_F = 'CREATE_PRODUCT_PORTFOLIO_F';
export const CREATE_PRODUCT_PORTFOLIO_R = 'CREATE_PRODUCT_PORTFOLIO_R';
export const CREATE_PRODUCT_PORTFOLIO_S = 'CREATE_PRODUCT_PORTFOLIO_S';

export interface CreateProductPortfolioRequestedType {
  type: typeof CREATE_PRODUCT_PORTFOLIO_R,
  payload: { productPortfolio: { name: string, keywords: string[] } };
}

export interface CreateProductPortfolioSucceededType {
  type: typeof CREATE_PRODUCT_PORTFOLIO_S,
  payload: { productPortfolio: ProductPortfolioDto };
}

export interface CreateProductPortfolioFailedType {
  type: typeof CREATE_PRODUCT_PORTFOLIO_F,
  payload: { companyFetchingError: string },
}

export const EDIT_PRODUCT_PORTFOLIO_F = 'EDIT_PRODUCT_PORTFOLIO_F';
export const EDIT_PRODUCT_PORTFOLIO_R = 'EDIT_PRODUCT_PORTFOLIO_R';
export const EDIT_PRODUCT_PORTFOLIO_S = 'EDIT_PRODUCT_PORTFOLIO_S';

export interface EditProductPortfolioRequestedType {
  type: typeof EDIT_PRODUCT_PORTFOLIO_R,
  payload: { productPortfolio: { _id: string; name: string, keywords: string[] } };
}

export interface EditProductPortfolioSucceededType {
  type: typeof EDIT_PRODUCT_PORTFOLIO_S,
  payload: { productPortfolio: ProductPortfolioDto };
}

export interface EditProductPortfolioFailedType {
  type: typeof EDIT_PRODUCT_PORTFOLIO_F,
  payload: { companyFetchingError: string },
}

export const ADD_SERVICE_TO_COMPANY = 'ADD_SERVICE_TO_COMPANY';
export const DELETE_SERVICE_FROM_COMPANY = 'DELETE_SERVICE_FROM_COMPANY';

export interface AddServiceToCompany {
  type: typeof ADD_SERVICE_TO_COMPANY,
  payload: { companyService: DropdownEntry, type: string },
}

export interface DeleteServiceFromCompany {
  type: typeof DELETE_SERVICE_FROM_COMPANY,
  payload: { companyService: string, type: string },
}

export const GET_COMPANY_R = 'GET_COMPANY_R';
export const GET_COMPANY_S = 'GET_COMPANY_S';
export const GET_COMPANY_F = 'GET_COMPANY_F';

export interface GetCompanyRequestedType {
  type: typeof GET_COMPANY_R,
  payload: { id: string };
}

export interface GetCompanySucceededType {
  type: typeof GET_COMPANY_S,
  payload: { company: Company };
}

export interface GetCompanyFailedType {
  type: typeof GET_COMPANY_F,
  payload: { companyFetchingError: string };
}

export const COMPANIES_EDIT_COMPANY_R = 'COMPANIES_EDIT_COMPANY_R';
export const COMPANIES_EDIT_COMPANY_S = 'COMPANIES_EDIT_COMPANY_S';
export const COMPANIES_EDIT_COMPANY_F = 'COMPANIES_EDIT_COMPANY_F';

export interface EditCompanyRequestedType {
  type: typeof COMPANIES_EDIT_COMPANY_R,
  payload: {
    formData: CreateCompany;
  },
}

export interface EditCompanySucceededType {
  type: typeof COMPANIES_EDIT_COMPANY_S,
}

export interface EditCompanyFailedType {
  type: typeof COMPANIES_EDIT_COMPANY_F,
  payload: { companyFetchingError: string },
}

export const COMPANIES_EDIT_SUCCEEDED = 'COMPANIES_EDIT_SUCCEEDED';

export interface EditCompanySucceededActionType {
  type: typeof COMPANIES_EDIT_SUCCEEDED,
}

export const COMPANIES_SEARCH_COMPANY_LIST_R = 'COMPANIES_SEARCH_COMPANY_LIST_R';
export const COMPANIES_SEARCH_COMPANY_LIST_S = 'COMPANIES_SEARCH_COMPANY_LIST_S';
export const COMPANIES_SEARCH_COMPANY_LIST_F = 'COMPANIES_SEARCH_COMPANY_LIST_F';
export const COMPANIES_SEARCH_COMPANY_LIST_INIT = 'COMPANIES_SEARCH_COMPANY_LIST_INIT';

export interface SearchCompanyListRequestedType {
  type: typeof COMPANIES_SEARCH_COMPANY_LIST_R,
  payload: { productPortfolioIds: string[] },
}

export interface SearchCompanyListSucceededType {
  type: typeof COMPANIES_SEARCH_COMPANY_LIST_S,
  payload: { productFilteredCompanies: Company[] },
}

export interface SearchCompanyListFailedType {
  type: typeof COMPANIES_SEARCH_COMPANY_LIST_F,
  payload: { companyFetchingError: string },
}

export interface SearchCompanyListInitType {
  type: typeof COMPANIES_SEARCH_COMPANY_LIST_INIT,
}
export const DELETE_PRODUCT_PORTFOLIO_F = 'DELETE_PRODUCT_PORTFOLIO_F';
export const DELETE_PRODUCT_PORTFOLIO_R = 'DELETE_PRODUCT_PORTFOLIO_R';
export const DELETE_PRODUCT_PORTFOLIO_S = 'DELETE_PRODUCT_PORTFOLIO_S';

export interface DeleteProductPortfolioRequestedType {
  type: typeof DELETE_PRODUCT_PORTFOLIO_R,
  payload: { id: string };
}

export interface DeleteProductPortfolioSucceededType {
  type: typeof DELETE_PRODUCT_PORTFOLIO_S,
}

export interface DeleteProductPortfolioFailedType {
  type: typeof DELETE_PRODUCT_PORTFOLIO_F,
  payload: { companyFetchingError: string },
}

export type CompaniesActionTypes =
  | GetCompanyListRequestedType
  | GetCompanyListSucceededType
  | GetCompanyListFailedType
  | CreateCompanyRequestedType
  | CreateCompanySucceededType
  | CreateCompanyFailedType
  | SetCreateCompanyField
  | GetCompanyServiceListRequestedType
  | GetCompanyServiceListSucceededType
  | GetCompanyServiceListFailedType
  | CreateCompanyServiceRequestedType
  | CreateCompanyServiceSucceededType
  | CreateCompanyServiceFailedType
  | AddServiceToCompany
  | DeleteServiceFromCompany
  | GetCompanyRequestedType
  | GetCompanySucceededType
  | GetCompanyFailedType
  | EditCompanyRequestedType
  | EditCompanySucceededType
  | EditCompanyFailedType
  | EditCompanySucceededActionType
  | GetProductPortfolioListRequestedType
  | GetProductPortfolioListSucceededType
  | GetProductPortfolioListFailedType
  | CreateProductPortfolioRequestedType
  | CreateProductPortfolioSucceededType
  | CreateProductPortfolioFailedType
  | EditProductPortfolioRequestedType
  | EditProductPortfolioSucceededType
  | EditProductPortfolioFailedType
  | SearchCompanyListRequestedType
  | SearchCompanyListSucceededType
  | SearchCompanyListFailedType
  | SearchCompanyListInitType
  | DeleteProductPortfolioRequestedType
  | DeleteProductPortfolioSucceededType
  | DeleteProductPortfolioFailedType;
