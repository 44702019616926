import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import ForgotPassword from 'components/ForgotPassword/ForgotPassword';
import { ReactComponent as LogoWideInverse } from 'assets/logo-wide-inverse.svg';
import { ReactComponent as LogoWide } from 'assets/logo-wide-normal.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { notification } from 'antd';
import './forgot-password-page.scss';

const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const { sendingEmailError, isEmailSent } = useSelector(({ user }: RootState) => user);

  useEffect(() => {
    if (sendingEmailError) {
      notification.error({
        message: t('pages.retorePassword.errors.emailSentErrorMessage') as string,
        description: t('pages.retorePassword.errors.emailSentErrorDescription') as string,
      });
    }
  }, [sendingEmailError]);

  return (
    <div className="page page-restore-password">
      <div className="logo">
        <LogoWide className="logo-normal" />
        <LogoWideInverse width="196" className="logo-inverse" />
      </div>
      <div className="restore-password-background">
        <div className="restore-password-box">
          <div className="header-container">
            <FontAwesomeIcon icon={faFingerprint} />
            <div>
              <div className="form-title">Elfelejtett jelszó</div>
              <div className="form-subtitle">
                {
                  !isEmailSent
                    ? 'Adja meg az email címét!'
                    : 'Helyreállítási email elküldve'
                }
              </div>
            </div>
          </div>
          {
            !isEmailSent
              ? <ForgotPassword />
              : (
                <div className="email-sent-container">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <div className="email-sent-title">
                    {t('pages.retorePassword.success.emailMessage') as string}
                  </div>
                  <div className="email-sent-decription">
                    {t('pages.retorePassword.success.emailDescription') as string}
                  </div>
                </div>
              )
          }
        </div>
      </div>

    </div>
  );
};

export default ForgotPasswordPage;
