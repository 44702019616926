import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import MainLayout, { MenuType } from 'layouts/MainLayout/MainLayout';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import { addServiceToCompany, createCompany, createCompanyService, deleteServiceFromCompany, getCompanyServiceList } from 'actions/companiesActions';
import { CompanyContact, DropdownEntry, PersonalContact, ProductPortfolioDto } from 'reducers/companies/types';
import { RootState } from 'reducers/rootReducer';
import CompanyAddressBlock from './blocks/CompanyAddressBlock';
import CompanyContactsBlock from './blocks/CompanyContactsBlock';
import PersonalContactsBlock from './blocks/PersonalContactsBlock';
import CompanyServicesBlock from './blocks/CompanyServicesBlock';
import CompanyLocationBlock from './blocks/CompanyLocationBlock';
import CompanyImageUploadBlock from './blocks/CompanyImageUploadBlock';
import ProductsBlock from './blocks/ProductsBlock';

// TODO: add more types + get them from API

const CreateCompanyPage = () => {
  const dispatch = useDispatch();
  const { createCompanySucceeded, createCompanyLoading } = useSelector(({ companies }: RootState) => companies);
  const initialState = {
    companyName: '',
    addressCountry: 'Magyarország',
    addressZip: '',
    addressCity: '',
    addressStreet: '',
    addressHouseNumber: '',
    addressFloor: '',
    addressDoor: '',
    addressDoorbell: '',
    vatId: '',
    website: '',
    logo: null,
    placeImage: null,
    geoLocation: {
      lat: 30,
      lng: 40,
    },
  };

  const [companyData, setCompanyData] = useState(initialState);
  const [companyContacts, setCompanyContacts] = useState<CompanyContact[]>([]);
  const [personalContacts, setPersonalContacts] = useState<PersonalContact[]>([]);
  const [productPortfolios, setProductPortfolios] = useState<ProductPortfolioDto[]>([]);

  useEffect(() => {
    if (createCompanySucceeded) {
      setCompanyData(initialState);
      setCompanyContacts([]);
      setPersonalContacts([]);
    }
  }, [createCompanySucceeded]);

  const handleAddCompanyContact = () => {
    setCompanyContacts(() => ([
      ...companyContacts,
      {
        label: '',
        email: '',
        phone: '',
      },
    ]));
  };

  const handleAddContact = () => {
    setPersonalContacts(() => ([
      ...personalContacts,
      {
        name: '',
        role: 'ügyvezető',
        email: '',
        phone: '',
      },
    ]));
  };

  const handleDeleteCompanyContact = (selectedContactIndex: number) => {
    setCompanyContacts(() => (
      companyContacts.filter((_, index) => (index !== selectedContactIndex))
    ));
  };

  const handleDeleteContact = (selectedContactIndex: number) => {
    setPersonalContacts(() => (
      personalContacts.filter((_, index) => (index !== selectedContactIndex))
    ));
  };

  const handleCompanyContactChange = (selectedContactIndex: number, field: string, value: string) => {
    setCompanyContacts(() => (
      companyContacts.map((contact, index) => {
        if (index === selectedContactIndex) {
          return {
            ...contact,
            [field]: value,
          };
        }

        return contact;
      })
    ));
  };

  const handleContactChange = (selectedContactIndex: number, field: string, value: string) => {
    setPersonalContacts(() => (
      personalContacts.map((contact, index) => {
        if (index === selectedContactIndex) {
          return {
            ...contact,
            [field]: value,
          };
        }

        return contact;
      })
    ));
  };

  const handleInputChange = (value: string | object, field: string) => {
    setCompanyData((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const handleSubmitForm = () => {
    dispatch(createCompany({
      companyName: companyData.companyName,
      address: {
        zip: companyData.addressZip,
        city: companyData.addressCity,
        street: companyData.addressStreet,
        houseNumber: companyData.addressHouseNumber,
        floor: companyData.addressFloor,
        door: companyData.addressDoor,
        doorbell: companyData.addressDoorbell,
      },
      vatId: companyData.vatId,
      geoLocation: companyData.geoLocation,
      logo: companyData.logo,
      placeImage: companyData.placeImage,
      website: companyData.website,
      companyContacts,
      personalContacts,
      productPortfolios,
    }));
  };

  const handleOnSelect = (value: any, option: any) => {
    if (!('key' in option)) {
      dispatch(createCompanyService({ approved: false, name: value, id: null }, 'create'));
    } else {
      dispatch(addServiceToCompany({ key: option.key, label: option.value } as DropdownEntry, 'create'));
    }
  };

  const handleOnDeSelect = (value: string) => {
    dispatch(deleteServiceFromCompany(value, 'create'));
  };

  const handleOnSearch = (event: any) => {
    if (event.length > 2) {
      dispatch(getCompanyServiceList(event));
    }
  };

  const handleOnBlur = (event: any) => {
    if (event.target.value.length >= 3) {
      dispatch(createCompanyService({ approved: false, name: event.target.value, id: null }, 'create'));
    }
  };

  const isValidForm = () => (
    companyData.companyName !== ''
    && companyData.vatId !== ''
  );

  const { companyServiceList } = useSelector(({ companies }: RootState) => companies);

  const {
    companyServices,
  } = useSelector(({ companies }: RootState) => companies.createCompany);

  const onSelectPortfolio = (selectedPortfolio: ProductPortfolioDto) => {
    setProductPortfolios([...productPortfolios, selectedPortfolio]);
  };

  const onDeselectPortfolio = (id: string) => {
    setProductPortfolios(productPortfolios.filter((item) => item._id !== id));
  };

  return (
    <MainLayout
      selectedMenu={MenuType.COMPANIES}
      customBodyStyle={{ paddingBottom: 90 }}
    >
      <Row gutter={[24, 24]} className="create-company-page">
        <Col span={24}>
          <Row gutter={[24, 0]}>
            <Col span={12} xs={24} lg={12}>
              <CompanyAddressBlock
                createCompany={companyData}
                handleInputChange={handleInputChange}
              />
            </Col>
            <Col span={12} xs={24} lg={12}>
              <CompanyServicesBlock
                onBlur={handleOnBlur}
                onSearch={debounce(handleOnSearch, 300)}
                onSelect={handleOnSelect}
                onDeselect={handleOnDeSelect}
                initialValue={companyServices.map((item) => ({ key: item.key || '', label: item.label || '' }))}
                data={companyServiceList}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[24, 0]}>
            <Col span={12} xs={24} lg={12}>
              <CompanyContactsBlock
                website={companyData.website}
                contacts={companyContacts}
                handleAddContact={handleAddCompanyContact}
                handleDeleteContact={handleDeleteCompanyContact}
                handleContactChange={handleCompanyContactChange}
                handleInputChange={handleInputChange}
              />
            </Col>
            <Col span={12} xs={24} lg={12}>
              <PersonalContactsBlock
                contacts={personalContacts}
                handleAddContact={handleAddContact}
                handleDeleteContact={handleDeleteContact}
                handleContactChange={handleContactChange}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[24, 0]}>
            <Col span={12} xs={24} lg={12}>
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <ProductsBlock
                    data={productPortfolios}
                    onSelectPortfolio={onSelectPortfolio}
                    onDeselectPortfolio={onDeselectPortfolio}
                  />
                </Col>
                <Col span={24}>
                  <CompanyImageUploadBlock
                    title="Céglogo"
                    type="LOGO"
                    fieldName="logo"
                    imageSize={180}
                    onImageChange={handleInputChange}
                    imageSource={companyData.logo}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <CompanyImageUploadBlock
                title="Bejárat"
                type="PLACE"
                fieldName="placeImage"
                imageSize={320}
                onImageChange={handleInputChange}
                imageSource={companyData.placeImage}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <CompanyLocationBlock
            defaultPosition={{
              lat: 47.513583,
              lng: 19.2843238,
            }}
            onMarkerChange={handleInputChange}
          />
        </Col>

        <Col span={24}>
          <Button
            type="primary"
            label="CÉG LÉTREHOZÁSA"
            onClick={handleSubmitForm}
            disabled={!isValidForm()}
            loading={createCompanyLoading}
          />
        </Col>
      </Row>
    </MainLayout>
  );
};

export default CreateCompanyPage;
