import { DropdownEntry, CompanyDetails, Company, ProductPortfolioDto } from './types';

export type CompaniesState = {
  companyList: Company[];
  companyServiceList: DropdownEntry[];
  companyListIsFetching: boolean;
  companyFetchingError: string | null,
  createCompany: CompanyDetails;
  createCompanySucceeded: boolean;
  company?: Company;
  companyEditLoading: boolean;
  companyEditSucceeded: boolean;
  companyEditFailed: boolean;
  companyFetched: boolean;
  createCompanyLoading: boolean;
  productPortfolioList: ProductPortfolioDto[];
  productPortfolio: ProductPortfolioDto | null;
  productPortfolioSucceeded: boolean;
  productFilteredCompanies: Company[];
  productPortfolioLoading: boolean;
  companyServiceIsEditing: boolean;
};

export const initialState: CompaniesState = {
  companyListIsFetching: false,
  companyList: [],
  companyServiceList: [],
  companyFetchingError: null,
  createCompany: {
    companyName: '',
    addressCountry: 'Magyarország',
    addressZip: '',
    addressCity: '',
    addressStreet: '',
    addressHouseNumber: '',
    addressFloor: '',
    addressDoor: '',
    addressDoorbell: '',
    vatId: '',
    logo: null,
    placeImage: null,
    geoLocation: {
      lat: 30,
      lng: 50,
    },
    website: '',
    companyContacts: [],
    personalContacts: [],
    companyServices: [],
    productPortfolios: [],
  },
  createCompanySucceeded: false,
  company: undefined,
  companyServiceIsEditing: false,
  companyEditLoading: false,
  companyEditSucceeded: false,
  companyEditFailed: false,
  companyFetched: false,
  createCompanyLoading: false,
  productPortfolioList: [],
  productPortfolio: null,
  productPortfolioSucceeded: false,
  productFilteredCompanies: [],
  productPortfolioLoading: false,
};
