import { all } from 'redux-saga/effects';
import { appSagas } from './appSaga';
import { userSagas } from './userSaga';
import { communitySagas } from './communitySaga';
import { companiesSagas } from './companiesSaga';

export default function* rootSaga() {
  yield all([
    ...appSagas,
    ...userSagas,
    ...communitySagas,
    ...companiesSagas,
  ]);
}
