import { takeEvery, put, call, all, PutEffect, CallEffect, AllEffect } from 'redux-saga/effects';
import * as COMMUNITYACTIONTYPES from 'consts/communityActionTypes';
import { Answer, PollData } from 'reducers/community/types';
import * as API from 'services/api';

export function* getPosts(): Generator<CallEffect | PutEffect> {
  try {
    const query = {
      $sort: JSON.stringify({
        createdAt: -1,
      }),
    };
    const posts = yield call(API.findDataService, 'community-posts', '/community-posts/', query);
    yield put({
      type: COMMUNITYACTIONTYPES.GET_POSTS_S,
      payload: {
        posts,
      },
    });
  } catch (error) {
    yield put({
      type: COMMUNITYACTIONTYPES.GET_POSTS_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* createPost(action: COMMUNITYACTIONTYPES.CreatePostRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const { content } = action.payload;
    yield call(API.createDataService, 'community-posts', '/community-posts', { content });
    yield put({
      type: COMMUNITYACTIONTYPES.CREATE_POSTS_S,
    });
    yield call(getPosts);
  } catch (error) {
    yield put({
      type: COMMUNITYACTIONTYPES.CREATE_POSTS_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getPolls(): Generator<CallEffect | PutEffect> {
  try {
    const query = {
      $sort: JSON.stringify({
        createdAt: -1,
      }),
    };
    const questions = yield call(API.findDataService, 'poll-questions', '/poll-questions', query);
    const options = yield call(API.findDataService, 'poll-answers', '/poll-answers', query);
    const polls = (questions as PollData[]).map((item) => ({
      question: item.question,
      options: item.options.map((answerId) => (options as Answer[]).find((optionId) => answerId === optionId._id)),
    }));
    yield put({
      type: COMMUNITYACTIONTYPES.GET_POLLS_S,
      payload: {
        polls,
      },
    });
  } catch (error) {
    yield put({
      type: COMMUNITYACTIONTYPES.GET_POLLS_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* createPoll(action: COMMUNITYACTIONTYPES.CreatePollRequestedType): Generator<AllEffect<CallEffect> | CallEffect | PutEffect> {
  try {
    const { poll } = action.payload;
    const answers = yield all(poll.options.map((item) => call(API.createDataService, 'poll-answers', '/poll-answers', {
      title: item,
      userIds: [],
    })));
    yield call(API.createDataService, 'poll-questions', '/poll-questions', {
      question: poll.question,
      options: (answers as Answer[]).map((item) => item._id),
    });
    yield put({
      type: COMMUNITYACTIONTYPES.CREATE_POLLS_S,
    });
    yield call(getPolls);
  } catch (error) {
    yield put({
      type: COMMUNITYACTIONTYPES.CREATE_POLLS_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* answerPoll(action: COMMUNITYACTIONTYPES.AnswerPollRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const { answeredId, answers, userId } = action.payload;
    const previouslyAnswered = answers.find((answerItem) => answerItem.userIds.includes(userId));
    const answeredQuestion = answers.find((answerItem) => answerItem._id === answeredId);
    const newAnswers = answeredQuestion?.userIds ? [...answeredQuestion?.userIds, userId] : [];
    yield call(API.patchDataService, 'poll-answers', '/poll-answers', answeredId, {
      userIds: newAnswers,
    });
    if (previouslyAnswered) {
      const userFilteredAnswers = previouslyAnswered.userIds.filter((id: string) => id !== userId);
      yield call(API.patchDataService, 'poll-answers', '/poll-answers', previouslyAnswered._id, {
        userIds: userFilteredAnswers,
      });
    }
    yield put({
      type: COMMUNITYACTIONTYPES.ANSWER_POLL_S,
    });
    yield call(getPolls);
  } catch (error) {
    yield put({
      type: COMMUNITYACTIONTYPES.ANSWER_POLL_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const communitySagas = [
  takeEvery(COMMUNITYACTIONTYPES.GET_POSTS_R, getPosts),
  takeEvery(COMMUNITYACTIONTYPES.CREATE_POSTS_R, createPost),
  takeEvery(COMMUNITYACTIONTYPES.GET_POLLS_R, getPolls),
  takeEvery(COMMUNITYACTIONTYPES.CREATE_POLLS_R, createPoll),
  takeEvery(COMMUNITYACTIONTYPES.ANSWER_POLL_R, answerPoll),
];
