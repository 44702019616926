import React from 'react';
import './answer.scss';

export interface AnswerProps {
  answered: boolean;
  percentage: number;
  answer: string;
  onAnswer: () => void;
}

const Answer: React.FC<AnswerProps> = (props) => {
  const { answered, percentage, answer, onAnswer } = props;
  return (
    <div className="poll-answer-container">
      <div
        className={`poll-answer-radio-container${answered ? ' answered' : ''}`}
        tabIndex={0}
        role="button"
        onClick={onAnswer}
      >
        <div className={`poll-answer-radio${answered ? ' answered' : ''}`} />
      </div>
      <div
        className="poll-answer-title-container"
        tabIndex={0}
        role="button"
        onClick={onAnswer}
      >
        <div className="poll-answer-background" style={{ width: `${percentage}%` }} />
        <div className="poll-answer-title">
          {answer}
        </div>
      </div>
      <div className="poll-answer-percentage">{`${percentage.toFixed(1)} %`}</div>
    </div>
  );
};

export default Answer;
