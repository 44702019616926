import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { getCompanyList } from 'actions/companiesActions';
import { Input, Select, Button } from '@flexboxapps/flbx-webapp-ui';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { RootState } from 'reducers/rootReducer';
import { createUser, getUsers } from 'actions/userActions';
import './new-user-page.scss';

export interface NewUserPageProps {
  location?: {
    search: string;
  };
}

const NewUserPage: FC<NewUserPageProps> = (props) => {
  const dispatch = useDispatch();
  const param = new URLSearchParams(props.location?.search);
  const companyId = param.get('companyId');

  const initialData = {
    lastname: '',
    firstname: '',
    email: '',
    role: 'leader',
    companyId: undefined as string | undefined,
  };

  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (companyId) {
      setData({
        ...data,
        companyId: companyId || '',
      });
    }
  }, [companyId]);

  useEffect(() => {
    dispatch(getCompanyList());
    dispatch(getUsers());
  }, []);

  const { companyList } = useSelector(({ companies }: RootState) => companies);
  const { userCreated } = useSelector(({ user }: RootState) => user);

  useEffect(() => {
    if (userCreated) {
      notification.success({
        message: 'Felhasználó létrehozása sikeres!',
      });
      setData(initialData);
    }
  }, [userCreated]);

  const onChange = (key: string, value: string) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const isCreateDisabled = () => (
    data.firstname === ''
    || data.email === ''
    || data.role === ''
    || data.companyId === undefined
    || !companyList
    || companyList.length === 0
  );

  const handleSubmit = () => {
    if (!isCreateDisabled()) {
      dispatch(createUser({
        ...data,
        companyId: data.companyId || '',
      }));
    }
  };

  const roles = [
    {
      label: 'Cégvezető',
      value: 'leader',
    },
    {
      label: 'Felhasználó',
      value: 'user',
    },
  ];

  return (
    <MainLayout>
      <div className="post-container">
        <div className="create-post-header-title">Új felhasználó létrehozása</div>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Input
              label="Vezetéknév"
              placeholder="Vezetéknév"
              value={data.lastname}
              onChange={(e) => onChange('lastname', e.target.value)}
            />
          </Col>
          <Col span={12}>
            <Input
              label="Keresztnév"
              placeholder="Keresztnév"
              value={data.firstname}
              onChange={(e) => onChange('firstname', e.target.value)}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Input
              label="Email cím"
              placeholder="Gépelje be az email címet"
              value={data.email}
              onChange={(e) => onChange('email', e.target.value)}
            />
          </Col>
        </Row>
        {!companyId && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Select
                label="Cég"
                placeholder="Válasszon egy céget"
                value={data.companyId}
                options={companyList.map((item) => ({
                  label: item.companyName,
                  value: item._id,
                }))}
                onChange={(value) => {
                  if (value) {
                    onChange('companyId', value.toString());
                  }
                }}
                showSearch
                size="large"
              />
            </Col>
          </Row>
        )}
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Select
              label="Jogkör"
              placeholder="Válasszon jogosultságot"
              value={data.role}
              options={roles}
              onChange={(value) => {
                if (value) {
                  onChange('role', value.toString());
                }
              }}
              showSearch
              size="large"
            />
          </Col>
        </Row>

        <Button
          type="primary"
          label="Felhasználó létrehozása"
          disabled={isCreateDisabled()}
          onClick={handleSubmit}
        />
      </div>
    </MainLayout>
  );
};

export default NewUserPage;
