import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import { editCompanySucceeded, getCompanyList } from 'actions/companiesActions';
import { RootState } from 'reducers/rootReducer';
import { Company } from 'reducers/companies/types';
import MainLayout, { MenuType } from 'layouts/MainLayout/MainLayout';
import Loader from 'components/Loader/Loader';
import CompanyListCard from 'components/CompanyListCard/CompanyListCard';
import CompanyDrawer from 'components/CompanyDrawer/CompanyDrawer';
import './companies-page.scss';
import { useNavigate } from 'react-router-dom';
import NavigationRoute from 'routes/NavigationRoute';

const CompaniesPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  const { isAdmin } = useSelector(({ user }: RootState) => user);
  const {
    companyList,
    companyListIsFetching,
  } = useSelector(({ companies }: RootState) => companies);

  const filteredCompanies = companyList.filter((company) => (
    company.companyName.toLowerCase().includes(filterValue.toLowerCase())
    || company.services.filter((service) => (service.toLowerCase().includes(filterValue.toLowerCase()))).length > 0
  ));

  return (
    <MainLayout selectedMenu={MenuType.COMPANIES}>
      <Loader
        isLoading={companyListIsFetching && !companyList.length}
        pageLoader
      >
        <div className="flbx-companies-container">
          <div className="company-list-header">
            <input
              placeholder="Kezdjen el gépelni a kereséshez"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              className="company-list-fiter-input"
            />
            {isAdmin && (
              <Button
                label="Új cég hozzáadása"
                type="primary"
                onClick={() => navigate(NavigationRoute.CreateCompany)}
              />
            )}
          </div>
          <div className="company-list">
            {
              filteredCompanies && !filteredCompanies.length
              && (
                <>
                  <div className="empty-block">Nincs megjelenítendő cég</div>
                  {isAdmin && (
                    <Button
                      label="Új cég hozzáadása"
                      type="primary"
                      onClick={() => navigate(NavigationRoute.CreateCompany)}
                      style={{
                        width: 220,
                        margin: '24px auto',
                      }}
                    />
                  )}
                </>
              )
            }
            <Row gutter={{ xs: 8, sm: 18, md: 4, lg: 20 }}>
              {
                filteredCompanies.map((companyCard) => (
                  <Col
                    key={companyCard._id}
                    span={6}
                    xl={6}
                    lg={8}
                    md={12}
                    sm={18}
                    xs={24}
                    style={{ display: 'flex' }}
                  >
                    <CompanyListCard
                      companyName={companyCard.companyName}
                      logoSrc={companyCard.logo}
                      companyServices={companyCard.services}
                      onClick={() => {
                        dispatch(editCompanySucceeded());
                        setSelectedCompany(companyCard);
                        setVisible(!visible);
                      }}
                    />
                  </Col>
                ))
              }
            </Row>
          </div>
          <CompanyDrawer
            companyDetails={selectedCompany}
            visible={visible}
            onOpen={(visibility) => {
              if (!visibility) {
                setTimeout(() => {
                  setSelectedCompany(null);
                }, 300);
              }
              setVisible(visibility);
            }}
          />
        </div>
      </Loader>
    </MainLayout>
  );
};

export default CompaniesPage;
