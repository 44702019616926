import { Auth, API } from 'aws-amplify';

export const createCognitoAuthEndpoint = (endpoint: string) => `/cognito${endpoint}`;

export const login = (email: string, password: string) => Auth.signIn(email, password);

export const getUser = () => Auth.currentUserInfo();

export const logout = () => Auth.signOut();

export const createDataService = (service: string, endpoint: string, data: any) => API
  .post(service, createCognitoAuthEndpoint(endpoint), { body: data });

export const findDataService = (service: string, endpoint: string, queryStringParameters: any = {}) => API
  .get(service, createCognitoAuthEndpoint(endpoint), { queryStringParameters });

export const patchDataService = (service: string, endpoint: string, id: number | string, data: any) => API
  .patch(service, `${createCognitoAuthEndpoint(endpoint)}/${id}`, { body: data });

export const deleteDataService = (service: string, endpoint: string, id: number | string) => API
  .del(service, `${createCognitoAuthEndpoint(endpoint)}/${id}`, {});

export const forgotPasswordSendEmail = (email: string) => Auth.forgotPassword(email);
