import { Users } from 'reducers/user/types';

export const USER_LOGIN_F = 'USER_LOGIN_F';
export interface UserLoginFailedType {
  type: typeof USER_LOGIN_F,
  payload: { errorMessage: string },
}

export const USER_LOGIN_R = 'USER_LOGIN_R';
export interface UserLoginRequestedType {
  type: typeof USER_LOGIN_R,
  payload: { email: string, password: string, next: string },
}

export const USER_LOGIN_S = 'USER_LOGIN_S';
export interface UserLoginSucceededType {
  type: typeof USER_LOGIN_S,
  payload: {
    email: string;
    userId: string;
    isAdmin: boolean;
    givenName: string;
    isLeader: boolean;
    companyId: string;
  };
}

export const USER_LOGOUT_F = 'USER_LOGOUT_F';

export const USER_LOGOUT_R = 'USER_LOGOUT_R';
export interface UserLogoutRequestedType {
  type: typeof USER_LOGOUT_R,
}

export const USER_LOGOUT_S = 'USER_LOGOUTS';
export interface UserLogoutSucceededType {
  type: typeof USER_LOGOUT_S,
}

export const RESTORE_USER_BY_TOKEN_F = 'RESTORE_USER_BY_TOKEN_F';
export interface RestoreUserByTokenFailedType {
  type: typeof RESTORE_USER_BY_TOKEN_F,
}

export const RESTORE_USER_BY_TOKEN_R = 'RESTORE_USER_BY_TOKEN_R';
export interface RestoreUserByTokenRequestedType {
  type: typeof RESTORE_USER_BY_TOKEN_R,
  payload: { next?: string },
}

export const RESTORE_USER_BY_TOKEN_S = 'RESTORE_USER_BY_TOKEN_S';
export interface RestoreUserByTokenSucceededType {
  type: typeof RESTORE_USER_BY_TOKEN_S,
  payload: {
    email: string;
    userId: string;
    isAdmin: boolean;
    givenName: string;
    isLeader: boolean;
    companyId: string;
  };
}

export const CREATE_USER_F = 'CREATE_USER_F';
export interface CreateUserFailedType {
  type: typeof CREATE_USER_F,
  payload: { errorMessage: string },
}

export const CREATE_USER_R = 'CREATE_USER_R';
export interface CreateUserRequestedType {
  type: typeof CREATE_USER_R,
  payload: {
    data: {
      lastname?: string,
      firstname: string,
      email: string;
      companyId: string;
      role: string;
    };
  };
}

export const CREATE_USER_S = 'CREATE_USER_S';
export interface CreateUserSucceededType {
  type: typeof CREATE_USER_S,
}

export const VERIFY_USER_F = 'VERIFY_USER_F';
export interface VerifyUserFailedType {
  type: typeof VERIFY_USER_F,
  payload: { errorMessage: string },
}

export const VERIFY_USER_R = 'VERIFY_USER_R';
export interface VerifyUserRequestedType {
  type: typeof VERIFY_USER_R,
  payload: {
    data: {
      email: string;
      password: string;
      token: string;
    };
  };
}

export const VERIFY_USER_S = 'VERIFY_USER_S';
export interface VerifyUserSucceededType {
  type: typeof VERIFY_USER_S,
}

export const GET_USERS_F = 'GET_USERS_F';
export interface GetUsersFailedType {
  type: typeof GET_USERS_F,
  payload: { errorMessage: string },
}

export const GET_USERS_R = 'GET_USERS_R';
export interface GetUsersRequestedType {
  type: typeof GET_USERS_R,
  payload: {
    id?: string;
  };
}

export const GET_USERS_S = 'GET_USERS_S';
export interface GetUsersSucceededType {
  type: typeof GET_USERS_S,
  payload: {
    users: Users;
  };
}

export const RESEND_INVITE_R = 'RESEND_INVITE_R';
export interface ResendInviteRequestedType {
  type: typeof RESEND_INVITE_R,
  payload: { userId: string },
}

export const RESEND_INVITE_S = 'RESEND_INVITE_S';
export interface ResendInviteSucceededType {
  type: typeof RESEND_INVITE_S,
  payload: { userId: string },
}

export const RESEND_INVITE_F = 'RESEND_INVITE_F';
export interface ResendInviteFailedType {
  type: typeof RESEND_INVITE_F,
  payload: { userId: string },
}

export const RESET_PASSWORD_R = 'RESET_PASSWORD_R';
export interface ResetPasswordRequestedType {
  type: typeof RESET_PASSWORD_R,
  payload: { userId: string },
}

export const RESET_PASSWORD_S = 'RESET_PASSWORD_S';
export interface ResetPasswordSucceededType {
  type: typeof RESET_PASSWORD_S,
  payload: { userId: string },
}

export const RESET_PASSWORD_F = 'RESET_PASSWORD_F';
export interface ResetPasswordFailedType {
  type: typeof RESET_PASSWORD_F,
  payload: { userId: string },
}

export const USER_FORGOT_PASSWORD_F = 'USER_FORGOT_PASSWORD_F';
export interface UserForgotPasswordFailedType {
  type: typeof USER_FORGOT_PASSWORD_F,
  payload: { errorMessage: string },
}

export const USER_FORGOT_PASSWORD_R = 'USER_FORGOT_PASSWORD_R';
export interface UserForgotPasswordRequestedType {
  type: typeof USER_FORGOT_PASSWORD_R,
  payload: { password: string, token: string, email: string },
}

export const USER_FORGOT_PASSWORD_S = 'USER_FORGOT_PASSWORD_S';
export interface UserForgotPasswordSucceededType {
  type: typeof USER_FORGOT_PASSWORD_S,
  payload: { email: string },
}

export const USER_SEND_EMAIL_F = 'USER_SEND_EMAIL_F';
export interface UserSendEmailFailedType {
  type: typeof USER_SEND_EMAIL_F,
  payload: { errorMessage: string },
}

export const USER_SEND_EMAIL_R = 'USER_SEND_EMAIL_R';
export interface UserSendEmailRequestedType {
  type: typeof USER_SEND_EMAIL_R,
  payload: { email: string },
}

export const USER_SEND_EMAIL_S = 'USER_SEND_EMAIL_S';
export interface UserSendEmailSucceededType {
  type: typeof USER_SEND_EMAIL_S,
}

export type UserActionTypes =
  | UserLoginRequestedType
  | UserLogoutRequestedType
  | RestoreUserByTokenRequestedType
  | RestoreUserByTokenFailedType
  | RestoreUserByTokenSucceededType
  | UserLoginSucceededType
  | RestoreUserByTokenSucceededType
  | UserLoginFailedType
  | UserLogoutSucceededType
  | CreateUserFailedType
  | CreateUserRequestedType
  | CreateUserSucceededType
  | VerifyUserFailedType
  | VerifyUserRequestedType
  | VerifyUserSucceededType
  | GetUsersFailedType
  | GetUsersRequestedType
  | GetUsersSucceededType
  | ResendInviteRequestedType
  | ResendInviteSucceededType
  | ResendInviteFailedType
  | GetUsersSucceededType
  | UserForgotPasswordFailedType
  | UserForgotPasswordRequestedType
  | UserForgotPasswordSucceededType
  | UserSendEmailFailedType
  | UserSendEmailRequestedType
  | UserSendEmailSucceededType;
