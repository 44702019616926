import * as TYPES from 'consts/userActionTypes';
import { initialState, UserState } from './initialState';

const userReducer = (state = initialState, action?: TYPES.UserActionTypes): UserState => {
  if (!action) return initialState;
  switch (action.type) {
    case TYPES.USER_LOGIN_R:
      return {
        ...state,
        isAuthenticating: true,
      };

    case TYPES.USER_LOGIN_S: {
      const { email, userId, isAdmin, givenName, isLeader, companyId } = action.payload;
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        hasAuthenticatingError: false,
        errorMessage: '',
        email,
        userId,
        isAdmin,
        isLeader,
        companyId,
        givenName,
      };
    }

    case TYPES.RESTORE_USER_BY_TOKEN_S: {
      const { isAdmin, email, userId, givenName, isLeader, companyId } = action.payload;
      return {
        ...state,
        email,
        isAdmin,
        givenName,
        isLeader,
        companyId,
        userId,
        isAuthenticated: true,
        restoreUserFailed: false,
      };
    }

    case TYPES.USER_LOGIN_F:
      return {
        ...state,
        isAuthenticating: false,
        hasAuthenticatingError: true,
        errorMessage: action.payload.errorMessage,
      };

    case TYPES.CREATE_USER_S:
      return {
        ...state,
        userCreated: true,
      };

    case TYPES.CREATE_USER_F:
    case TYPES.CREATE_USER_R:
      return {
        ...state,
        userCreated: false,
      };

    case TYPES.VERIFY_USER_F:
      return {
        ...state,
        verifyLoading: false,
        verifySucceeded: false,
      };

    case TYPES.VERIFY_USER_R:
      return {
        ...state,
        verifyLoading: true,
        verifySucceeded: false,
      };

    case TYPES.VERIFY_USER_S:
      return {
        ...state,
        verifyLoading: false,
        verifySucceeded: true,
      };

    case TYPES.GET_USERS_S:
      return {
        ...state,
        users: action.payload.users,
      };

    case TYPES.RESEND_INVITE_R: {
      const { userId } = action.payload;
      const users = { ...state.users };

      if (users) {
        users[userId] = {
          ...users[userId],
          resendingInvite: true,
        };
      }

      return {
        ...state,
        ...users,
      };
    }

    case TYPES.RESEND_INVITE_S:
    case TYPES.RESEND_INVITE_F: {
      const { userId } = action.payload;

      const users = { ...state.users };

      if (users) {
        users[userId] = {
          ...users[userId],
          resendingInvite: false,
        };
      }

      return {
        ...state,
        ...users,
      };
    }

    case TYPES.USER_SEND_EMAIL_R:
      return {
        ...state,
        isSendingEmail: true,
        isEmailSent: false,
      };

    case TYPES.USER_SEND_EMAIL_S: {
      return {
        ...state,
        isSendingEmail: false,
        isEmailSent: true,
        sendingEmailError: false,
        errorMessage: '',
      };
    }

    case TYPES.USER_SEND_EMAIL_F:
      return {
        ...state,
        isSendingEmail: false,
        sendingEmailError: true,
        errorMessage: action.payload.errorMessage,
      };

    case TYPES.USER_FORGOT_PASSWORD_R:
      return {
        ...state,
        forgotPasswordSucceeded: false,
      };

    case TYPES.USER_FORGOT_PASSWORD_S:
      return {
        ...state,
        forgotPasswordSucceeded: true,
        forgotPasswordError: false,
        errorMessage: '',
      };

    case TYPES.USER_FORGOT_PASSWORD_F:
      return {
        ...state,
        forgotPasswordSucceeded: false,
        forgotPasswordError: true,
        errorMessage: action.payload.errorMessage,
      };

    case TYPES.USER_LOGOUT_S:
      return initialState;

    case TYPES.RESTORE_USER_BY_TOKEN_F:
      return {
        ...state,
        restoreUserFailed: true,
      };

    case TYPES.RESTORE_USER_BY_TOKEN_R:
      return {
        ...state,
        restoreUserFailed: false,
      };

    case TYPES.USER_LOGOUT_R:
    case TYPES.GET_USERS_R:
    case TYPES.GET_USERS_F:
      return state;

    default:
      return state;
  }
};

export default userReducer;
