import React, { FC, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faUser } from '@fortawesome/pro-duotone-svg-icons';
import { IconInput, Button } from '@flexboxapps/flbx-webapp-ui';
import { validateEmail } from 'services/services';
import { sendEmail } from 'actions/userActions';

const ForgotPassword: FC = () => {
  const [email, setEmail] = useState('');
  const emailRef = useRef(email);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState({
    emailError: false,
  });
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      dispatch(sendEmail(emailRef.current));
    }
  };

  const setEmailError = (emailValue: string) => {
    if (validateEmail(emailValue)) {
      setError({ ...error, emailError: validateEmail(emailValue) });
      return true;
    }
    setError({ ...error, emailError: false });
    return null;
  };

  useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleForgotPassword = () => {
    if (!setEmailError(email)) {
      dispatch(sendEmail(email));
    }
  };

  return (
    <div className="login-container">
      <div className="input-container">
        {
          error.emailError && (
            <div className="error-message">
              {t('pages.login.errors.emailError') as string}
            </div>
          )
        }
        <IconInput
          value={email}
          placeholder={t('pages.login.inputs.email')}
          iconName={faUser}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (error.emailError) {
              setEmailError(e.target.value);
            }
            setEmail(e.target.value);
            emailRef.current = e.target.value;
          }}
          onBlur={() => { setEmailError(email); }}
          name="username"
        />
      </div>
      <div className="restore-password-button-container">
        <Button
          label="Email küldése"
          onClick={handleForgotPassword}
          type="primary"
          disabled={!email}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
