import React, { FC } from 'react';
import './hamburger-icon.scss';

export interface HamburgerIconProps {
  onClick: () => void;
  className?: string;
}

const HamburgerIcon: FC<HamburgerIconProps> = ({ onClick, className }) => (
  <div
    className={`hamburger-icon-container ${className || ''}`}
    onClick={onClick}
    tabIndex={0}
    role="button"
  >
    <div className="hamburger-icon" />
    <div className="hamburger-icon" />
    <div className="hamburger-icon" />
  </div>
);

export default HamburgerIcon;
