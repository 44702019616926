import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout, { MenuType } from 'layouts/MainLayout/MainLayout';
import { getPosts } from 'actions/communityActions';
import { RootState } from 'reducers/rootReducer';
import Post from 'components/Post/Post';
import Loader from 'components/Loader/Loader';
import HighlightedInfo from 'components/HighlightedInfo/HighlightedInfo';
import WhatsNew from 'components/WhatsNew/WhatsNew';
import CreatePost from 'components/CreatePost/CreatePost';
import './main-page.scss';

const MainPage = () => {
  const dispatch = useDispatch();
  const {
    community: { posts, postLoading },
    user: { isAdmin, givenName },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch]);

  return (
    <MainLayout
      selectedMenu={MenuType.POSTS}
      rightPanel={(
        <Row
          gutter={[0, 36]}
        >
          <Col
            span={24}
            xl={24}
          >
            <HighlightedInfo
              title="🚀 START!"
              date="2021-09-25"
              content={`
                Köszöntjük a Nagytarcsa Ipari Terület közösségi felületén!
                A mai nappal elindult a közösségi webalkalmazás, a következő hetekben további funkciókkal bővül majd a szolgáltatás.
              `}
            />
          </Col>
          <Col
            span={24}
            xl={24}
          >
            <WhatsNew
              items={[
                { date: '2021-09-25', update: 'Hírfolyam oldal' },
                { date: '2021-09-24', update: 'Cégtérkép' },
                { date: '2021-09-23', update: 'Cég- és szolgáltatáskereső' },
              ]}
            />
          </Col>
        </Row>
      )}
    >
      <Loader isLoading={posts === null || postLoading} pageLoader>
        {
          isAdmin
          && (
            <CreatePost userName={givenName} />
          )
        }
        {
          !isAdmin
          && (
            <h1 className="main-page-welcome">{`Üdvözöljük, ${givenName}!`}</h1>
          )
        }
        {
          posts && posts.length
            ? posts.map((item) => (
              <Post key={item._id} {...item} />
            ))
            : <div className="empty-block">Nincs megjelenítendő bejegyzés</div>
        }
      </Loader>
    </MainLayout>
  );
};

export default MainPage;
