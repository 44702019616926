import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout, { MenuType } from 'layouts/MainLayout/MainLayout';
import { getCompanyList } from 'actions/companiesActions';
import { RootState } from 'reducers/rootReducer';
import CompanyListCard from 'components/CompanyListCard/CompanyListCard';
import CompanyDrawer from 'components/CompanyDrawer/CompanyDrawer';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import { Company } from 'reducers/companies/types';
import './company-map-page.scss';

const CompanyMapPage = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState('');
  const { companyList } = useSelector(({ companies }: RootState) => companies);
  const [visible, setVisible] = useState(false);
  const [mapSelected, setMapSelected] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const filteredCompanies = companyList.filter((company) => (
    company.companyName.toLowerCase().includes(filterValue.toLowerCase())
    || company.services.filter((service: string) => (service.toLowerCase().includes(filterValue.toLowerCase()))).length > 0
  ));

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  const defaultPosition = {
    lat: 47.513548827368815,
    lng: 19.284342881011977,
  };

  const mapContainerStyle: React.CSSProperties = {
    height: 'calc(100vh - 136px)',
  };

  return (
    <MainLayout
      selectedMenu={MenuType.COMPANYMAP}
      rightPanel={(
        <Row gutter={[0, 36]}>
          <Col span={24}>
            <div className="company-map-panel">
              <div className="company-map-panel-company-list">
                {filteredCompanies.map((companyCard) => (
                  <CompanyListCard
                    companyName={companyCard.companyName}
                    logoSrc={companyCard.logo}
                    companyServices={companyCard.services}
                    onClick={() => {
                      setSelectedCompany(companyCard);
                      setMapSelected(true);
                    }}
                    isSmall
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      )}
    >
      <CompanyDrawer
        companyDetails={selectedCompany}
        visible={visible}
        onOpen={(visibility) => {
          setVisible(visibility);
        }}
      />
      <div className="company-list-header">
        <input
          placeholder="Kezdjen el gépelni a kereséshez"
          value={filterValue}
          onChange={(e) => {
            setFilterValue(e.target.value);
            setSelectedCompany(null);
          }}
          className="company-list-fiter-input"
        />
      </div>
      <GoogleMap
        zoom={15.5}
        gestureHandling="normal"
        defaultPosition={defaultPosition}
        companyProfiles={filteredCompanies || []}
        containerStyle={mapContainerStyle}
        selected={mapSelected && !!selectedCompany}
        selectedCompany={selectedCompany}
        onMarkerClick={(company) => {
          setMapSelected(true);
          setSelectedCompany(company);
          setVisible(!visible);
        }}
      />
    </MainLayout>
  );
};

export default CompanyMapPage;
