/* eslint-disable */
import React, { FC, useState } from 'react';
import { Layout, Row, Col, Drawer } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NavigationRoute from 'routes/NavigationRoute';
import { logout } from 'actions/userActions';
import './main-layout.scss';
import MainNav from 'layouts/components/MainNav/MainNav';
import { ReactComponent as NIPKLogo } from './nipk.svg';
import HamburgerIcon from 'components/HamburgerIcon/HamburgerIcon';

const { Content } = Layout;

export enum MenuType {
  POSTS = 'POSTS',
  VOTES = 'VOTES',
  COMPANIES = 'COMPANIES',
  COMPANYMAP = 'COMPANYMAP',
  LOGOUT = 'LOGOUT',
  USERS = 'USERS',
  PRODUCT_COMPANIES = 'PRODUCT_COMPANIES',
  PRODUCT_PORTFOLIOS = 'PRODUCT_PORTFOLIOS',
}

export interface MainLayoutProps {
  children?: React.ReactNode;
  selectedMenu?: MenuType;
  rightPanel?: React.ReactNode;
  customBodyStyle?: React.CSSProperties;
  menuClassName?: string;
  leftPanelClassName?: string;
}

const MainLayout: FC<MainLayoutProps> = ({
  children,
  selectedMenu = '',
  rightPanel = undefined,
  customBodyStyle = {},
  menuClassName = '',
  leftPanelClassName = '',
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const menuClickHandler = (key: string) => {
    switch (key) {
      case MenuType.POSTS: {
        navigate(NavigationRoute.Posts);
        break;
      }
      case MenuType.VOTES: {
        navigate(NavigationRoute.Votes);
        break;
      }
      case MenuType.COMPANIES: {
        navigate(NavigationRoute.Companies);
        break;
      }
      case MenuType.COMPANYMAP: {
        navigate(NavigationRoute.CompanyMap);
        break;
      }
      case MenuType.PRODUCT_COMPANIES: {
        navigate(NavigationRoute.ProductCompanies);
        break;
      }
      case MenuType.USERS: {
        navigate(NavigationRoute.Users);
        break;
      }
      case MenuType.PRODUCT_PORTFOLIOS: {
        navigate(NavigationRoute.ProductPortfolios);
        break;
      }
      case MenuType.LOGOUT: {
        dispatch(logout());
        break;
      }
      default: break;
    }
  };

  return (
    <Layout className="layout">
      <Row className="layout-main" gutter={[36, 0]}>
        <HamburgerIcon onClick={() => setVisible(!visible)} className={menuClassName} />
        <Drawer
          placement="left"
          closable={false}
          onClose={() => setVisible(!visible)}
          visible={visible}
          key="left"
        >
          <MainNav
            selectedMenu={selectedMenu}
            menuClickHandler={menuClickHandler}
          />
        </Drawer>
        <Col flex="240px" className={`left-panel ${leftPanelClassName}`}>
          <NIPKLogo className="community-logo" />
          <MainNav
            selectedMenu={selectedMenu}
            menuClickHandler={menuClickHandler}
          />
        </Col>

        <Col flex={rightPanel ? 'auto' : '1'} style={{ overflow: 'auto' }}>
          <Content className="content-block" style={customBodyStyle}>
            {children}
          </Content>
        </Col>

        {
          rightPanel
          && (
            <Col flex="336px" className="right-panel">
              {rightPanel}
            </Col>
          )
        }
      </Row>
    </Layout>
  );
};

export default MainLayout;
