/* eslint-disable */

import { AppActionTypes, APP_CHANGE_LANGUAGE } from 'consts/appActionTypes';
import { initialState, AppState } from './initialState';

// eslint-disable-next-line
const appReducer = (state = initialState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case APP_CHANGE_LANGUAGE:
      return {
        ...state,
        lang: action.payload.lang,
      };
    default:
      return state;
  }
};

export default appReducer;
