enum NavigationRoute {
  Posts = '/',
  Login = '/login',
  Votes = '/votes',
  Companies = '/companies',
  CompanyMap = '/company-map',
  CreateCompany = '/companies/new',
  CreateUser = '/user/create',
  VerifyUser = '/user/verify',
  Users = '/users',
  EditCompany = '/companies/edit',
  ForgotPassword = '/forgot-password',
  ProductCompanies = '/companies/product-search',
  ProductPortfolios = '/product-portfolios',
  NewProductPortfolio = '/product-portfolio/new',
  EditProductPortfolio = '/product-portfolio/edit/:id',
}

export default NavigationRoute;
