import React, { FC } from 'react';
import './highlighted-info.scss';

export interface HighlightedInfoProps {
  title: string;
  date: string;
  content: string;
}

const HighlightedInfo: FC<HighlightedInfoProps> = ({
  title,
  date,
  content,
}) => (
  <div className="highlighted-info">
    <div className="highlighted-info-title">{title}</div>
    <div className="highlighted-info-date">{date}</div>
    <div className="highlighted-info-content">{content}</div>
  </div>
);

export default HighlightedInfo;
