import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { getCompanyList, createProductPortfolio } from 'actions/companiesActions';
import { Input, Button } from '@flexboxapps/flbx-webapp-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { RootState } from 'reducers/rootReducer';
import { ProductPortfolioDto } from 'reducers/companies/types';
import './new-product-portfolio-page.scss';

const NewProductPortfolioPage: FC = () => {
  const dispatch = useDispatch();

  const initialData: ProductPortfolioDto = {
    name: '',
    keywords: [],
    approved: false,
  };

  const [data, setData] = useState(initialData);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  const { productPortfolioSucceeded } = useSelector(({ companies }: RootState) => companies);

  useEffect(() => {
    if (productPortfolioSucceeded) {
      notification.success({
        message: 'Termék létrehozása sikeres!',
      });
      setData(initialData);
    }
  }, [productPortfolioSucceeded]);

  const onChange = (key: keyof ProductPortfolioDto, value: string | string[]) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const isCreateDisabled = useMemo(() => data.name === '' || !data.keywords.length, [data]);

  const handleSubmit = () => {
    if (!isCreateDisabled) {
      dispatch(createProductPortfolio(data.name, data.keywords));
    }
  };

  const addKeyword = () => {
    onChange('keywords', data.keywords.concat(keyword));
  };

  const deleteKeyword = (index: number) => {
    onChange('keywords', data.keywords.filter((_, keywordIndex) => keywordIndex !== index));
  };
  return (
    <MainLayout>
      <div className="post-container">
        <div className="create-post-header-title">Új termék létrehozása</div>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Input
              label="Megnevezés"
              placeholder="Megnevezés"
              value={data.name}
              onChange={(e) => onChange('name', e.target.value)}
            />
          </Col>
          <Col span={24}>
            <Input
              label="Kulcsszó"
              placeholder="Kulcsszó hozzáadása"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onPressEnter={() => {
                addKeyword();
                setKeyword('');
              }}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <h3 className="create-post-header-subtitle">Kulcsszavak</h3>
          </Col>
          <div className="product-portfolio-tag-container" style={{ marginLeft: '12px' }}>
            {data.keywords ? data.keywords.map((item, index) => (
              <div className="product-portfolio-tag">
                {item}
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  onClick={() => deleteKeyword(index)}
                  size="1x"
                  color="black"
                  className="remove-tag"
                />
              </div>
            )) : null}
          </div>
        </Row>
        <Button
          type="primary"
          label="Termék létrehozása"
          disabled={isCreateDisabled}
          onClick={handleSubmit}
        />
      </div>
    </MainLayout>
  );
};

export default NewProductPortfolioPage;
