import {
  CommunityActionTypes,
  GET_POSTS_S,
  GET_POSTS_R,
  GET_POSTS_F,
  GET_POLLS_S,
  GET_POLLS_R,
  GET_POLLS_F,
  CREATE_POSTS_R,
  CREATE_POSTS_S,
  CREATE_POSTS_F,
  CREATE_POLLS_R,
  CREATE_POLLS_S,
  CREATE_POLLS_F,
  ANSWER_POLL_F,
  ANSWER_POLL_R,
  ANSWER_POLL_S,
} from 'consts/communityActionTypes';
import { initialState, CommunityState } from './initialState';

const communityReducer = (state = initialState, action?: CommunityActionTypes): CommunityState => {
  if (!action) return initialState;
  switch (action.type) {
    case GET_POSTS_S:
      return {
        ...state,
        posts: action.payload.posts,
        postLoading: false,
        postLastFetched: new Date(),
      };

    case GET_POSTS_R:
      return {
        ...state,
        postLoading: true,
      };

    case GET_POSTS_F:
      return {
        ...state,
        postLoading: false,
      };

    case CREATE_POSTS_R:
      return {
        ...state,
        postCreating: true,
      };

    case CREATE_POSTS_S:
    case CREATE_POSTS_F:
      return {
        ...state,
        postCreating: false,
      };

    case GET_POLLS_S:
      return {
        ...state,
        polls: action.payload.polls,
        pollLoading: false,
      };

    case GET_POLLS_R:
      return {
        ...state,
        pollLoading: true,
      };

    case GET_POLLS_F:
      return {
        ...state,
        pollLoading: false,
      };

    case CREATE_POLLS_R:
      return {
        ...state,
        pollsCreating: true,
      };

    case CREATE_POLLS_S:
    case CREATE_POLLS_F:
      return {
        ...state,
        pollsCreating: false,
      };

    case ANSWER_POLL_R:
      return {
        ...state,
        answerLoading: true,
      };

    case ANSWER_POLL_S:
    case ANSWER_POLL_F:
      return {
        ...state,
        answerLoading: false,
      };

    default:
      return state;
  }
};

export default communityReducer;
