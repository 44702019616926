import React, { FC } from 'react';
import { Table } from 'antd';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import InputBlock from 'components/InputBlock/InputBlock';
import { Users } from 'reducers/user/types';
import { Column } from 'routes/UsersPage/UsersPage';
import { useNavigate } from 'react-router-dom';
import NavigationRoute from 'routes/NavigationRoute';

export interface UsersBlockProps {
  users: Users | null;
  companyId: string | undefined;
}

const UsersBlock: FC<UsersBlockProps> = ({ users, companyId }) => {
  const navigate = useNavigate();
  const listTableColumns: Column[] = [
    {
      title: 'Név',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Email hitelesítve',
      dataIndex: 'email_verified',
      key: 'email_verified',
    },
    {
      title: 'Felhasználó szerepköre',
      dataIndex: 'userRole',
      key: 'userRole',
    },
  ];

  const dataSource = users ? Object.keys(users).map((userKey) => ({
    name: `${users[userKey].family_name} ${users[userKey].given_name}`,
    email: users[userKey].email,
    email_verified: users[userKey].email_verified === 'true' ? 'Igen' : 'Nem',
    userRole: users[userKey].userRole,
  })) : [];

  return (
    <InputBlock
      title="Felhasználók"
      button={(
        <Button
          type="primary"
          label="FELHASZNÁLÓ LÉTREHOZÁSA"
          onClick={() => navigate(`${NavigationRoute.CreateUser}?companyId=${companyId}`)}
        />
      )}
    >
      <Table
        className="users-table"
        columns={listTableColumns}
        dataSource={dataSource}
        showSorterTooltip={false}
        pagination={false}
      />
    </InputBlock>
  );
};

export default UsersBlock;
