import { takeEvery, call, put, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  USER_LOGIN_R, USER_LOGIN_S, USER_LOGIN_F, UserLoginRequestedType,
  RESTORE_USER_BY_TOKEN_R, RESTORE_USER_BY_TOKEN_S, RESTORE_USER_BY_TOKEN_F,
  USER_LOGOUT_R, USER_LOGOUT_S, USER_LOGOUT_F,
  CREATE_USER_R, CREATE_USER_S, CREATE_USER_F, CreateUserRequestedType,
  VERIFY_USER_R, VERIFY_USER_S, VERIFY_USER_F, VerifyUserRequestedType,
  GET_USERS_R, GET_USERS_F, GET_USERS_S, GetUsersRequestedType,
  USER_SEND_EMAIL_R, USER_SEND_EMAIL_S, USER_SEND_EMAIL_F, UserSendEmailRequestedType,
  USER_FORGOT_PASSWORD_R, USER_FORGOT_PASSWORD_S, USER_FORGOT_PASSWORD_F, UserForgotPasswordRequestedType,
  RESEND_INVITE_R, RESEND_INVITE_S, RESEND_INVITE_F, ResendInviteRequestedType, RESET_PASSWORD_R, ResetPasswordRequestedType,
} from 'consts/userActionTypes';
import { RootState } from 'reducers/rootReducer';
import * as API from 'services/api';

const getUserStore = ({ user }: RootState) => (user);

export interface Attributes {
  'custom:isAdmin': '1' | '0';
  'custom:companyId': string;
  'custom:userRole': 'leader' | 'user';
  given_name: string;
  email: string;
}

export const userIsAdmin = (attributes: { 'custom:isAdmin': '1' | '0' }) => (
  attributes['custom:isAdmin'] === '1'
);

export const userIsLeader = (attributes: { 'custom:userRole': 'leader' | 'user' }) => (
  attributes['custom:userRole'] === 'leader'
);

export function* login(action: UserLoginRequestedType): any {
  try {
    const { email, password } = action.payload;
    yield call(API.login, email, password);
    const { id, attributes } = yield call(API.getUser);

    yield put({
      type: USER_LOGIN_S,
      payload: {
        // TODO: same at restoreUser payload
        email,
        userId: id,
        givenName: attributes.given_name,
        isAdmin: userIsAdmin(attributes),
        isLeader: userIsLeader(attributes),
        companyId: attributes['custom:companyId'],
      },
    });
  } catch (error) {
    yield put({
      type: USER_LOGIN_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* restoreUser(): any {
  try {
    const userData = yield call(API.getUser);
    const { attributes, id } = userData;
    yield put({
      type: RESTORE_USER_BY_TOKEN_S,
      payload: {
        email: attributes.email,
        userId: id,
        givenName: attributes.given_name,
        isAdmin: userIsAdmin(attributes),
        isLeader: userIsLeader(attributes),
        companyId: attributes['custom:companyId'],
      },
    });
  } catch (error) {
    yield put({
      type: RESTORE_USER_BY_TOKEN_F,
    });
    console.log((error as Error).message); // eslint-disable-line
  }
}

export function* logout() {
  try {
    yield call(API.logout);
    yield put({
      type: USER_LOGOUT_S,
    });
    window.open('https://nipkoz.hu/', '_self');
  } catch (error) {
    console.log((error as Error).message); // eslint-disable-line
    yield put({
      type: USER_LOGOUT_F,
    });
  }
}

export function* createUser(action: CreateUserRequestedType): any {
  try {
    const { data } = action.payload;
    yield call(API.createDataService, 'community-users', '/community-users/create', data);
    yield put({
      type: CREATE_USER_S,
    });
  } catch (error) {
    yield put({
      type: CREATE_USER_F,
    });
    console.log((error as Error)); // eslint-disable-line
  }
}

export function* verifyUser(action: VerifyUserRequestedType): any {
  try {
    const { data } = action.payload;
    yield call(API.createDataService, 'community-users', '/community-users/verify', data);
    yield put({
      type: VERIFY_USER_S,
    });
  } catch (error) {
    yield put({
      type: VERIFY_USER_F,
    });
    console.log((error as Error)); // eslint-disable-line
  }
}

function* resendInvite(action: ResendInviteRequestedType): any {
  const { userId } = action.payload;

  try {
    const { users } = yield select(getUserStore);
    const data = {
      username: users[userId].email,
    };
    yield call(API.createDataService, 'community-users', '/community-users/reinvite', data);
    yield put({
      type: RESEND_INVITE_S,
      payload: {
        userId,
      },
    });
    notification.success({
      message: 'A regisztrációs kód újraküldése megtörtént',
    });
  } catch (error: any) {
    notification.error({
      message: 'Hiba történt a regisztrációs kód újraküldése közben.',
    });
    yield put({
      type: RESEND_INVITE_F,
      payload: {
        userId,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* resetPassword(action: ResetPasswordRequestedType): any {
  const { userId } = action.payload;

  try {
    const { users } = yield select(getUserStore);
    const data = {
      username: users[userId].email,
    };
    yield call(API.createDataService, 'community-users', '/community-users/reset', data);
    yield put({
      type: RESEND_INVITE_S,
      payload: {
        userId,
      },
    });
    notification.success({
      message: 'A jelszó helyreállításához szükséges email-t elküldtük.',
    });
  } catch (error: any) {
    notification.error({
      message: 'Hiba történt a jelszó helyreállítása közben.',
    });
    yield put({
      type: RESEND_INVITE_F,
      payload: {
        userId,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export interface UserType {
  Attributes: { Name: string; Value: string }[];
  Username: string;
}

export function* getUsers(action: GetUsersRequestedType): any {
  try {
    const { id } = action.payload;
    const idString = id ? `/?companyId=${id}` : '';
    const data = yield call(API.findDataService, 'community-users', `/community-users${idString}`);
    const users = data.users.reduce((acc: object, curr: UserType) => ({
      ...acc,
      [curr.Username]: curr.Attributes.reduce((innerAcc: object, attributeItem) => ({
        ...innerAcc,
        [attributeItem.Name.replace('custom:', '')]: attributeItem.Value,
      }), {}),
    }), {});
    yield put({
      type: GET_USERS_S,
      payload: {
        users,
      },
    });
  } catch (error) {
    yield put({
      type: GET_USERS_F,
    });
    console.log((error as Error)); // eslint-disable-line
  }
}

function* sendEmail(action: UserSendEmailRequestedType) {
  try {
    const { email } = action.payload;
    yield call(API.forgotPasswordSendEmail, email);
    yield put({
      type: USER_SEND_EMAIL_S,
    });
  } catch (error) {
    console.log((error as Error)); // eslint-disable-line
    yield put({
      type: USER_SEND_EMAIL_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
  }
}

function* forgotPassword(action: UserForgotPasswordRequestedType) {
  try {
    yield call(API.createDataService, 'community-users', '/community-users/password-reset', action.payload);
    yield put({
      type: USER_FORGOT_PASSWORD_S,
    });
  } catch (error) {
    notification.error({
      message: 'Hiba történt a jelszó helyreállítása közben.',
    });
    console.log((error as Error)); // eslint-disable-line
    yield put({
      type: USER_FORGOT_PASSWORD_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
  }
}

export const userSagas = [
  takeEvery(USER_LOGIN_R, login),
  takeEvery(RESTORE_USER_BY_TOKEN_R, restoreUser),
  takeEvery(USER_LOGOUT_R, logout),
  takeEvery(CREATE_USER_R, createUser),
  takeEvery(VERIFY_USER_R, verifyUser),
  takeEvery(GET_USERS_R, getUsers),
  takeLatest(RESEND_INVITE_R, resendInvite),
  takeLatest(RESET_PASSWORD_R, resetPassword),
  takeEvery(USER_SEND_EMAIL_R, sendEmail),
  takeEvery(USER_FORGOT_PASSWORD_R, forgotPassword),
];
