import React, { FC, useEffect, useMemo } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import ROUTES from 'routes';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { restoreUserByToken } from 'actions/userActions';
import NavigationRoute from 'routes/NavigationRoute';

const App: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated, restoreUserFailed } = useSelector(({ user }: RootState) => user);

  const publicRoutes = [
    '/login',
    '/user/verify',
    '/forgot-password',
    '/restore-password',
  ];

  useEffect(() => {
    if (restoreUserFailed && !publicRoutes.includes(pathname)) {
      navigate(NavigationRoute.Login);
    }
  }, [restoreUserFailed]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(restoreUserByToken(''));
    }
  }, [isAuthenticated]);

  const withAuth = (component: React.ReactNode) => useMemo(() => (isAuthenticated ? component : null), [isAuthenticated]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/posts" />} />
      <Route path="/login" element={<ROUTES.LoginPage />} />
      <Route path="/user/verify" element={<ROUTES.VerifyUserPage />} />
      <Route path="/forgot-password" element={<ROUTES.ForgotPasswordPage />} />
      <Route path="/restore-password" element={<ROUTES.RestorePasswordPage />} />
      <Route path="/posts" element={withAuth(<ROUTES.MainPage />)} />
      <Route path="/votes" element={withAuth(<ROUTES.VotePage />)} />
      <Route path="/companies" element={withAuth(<ROUTES.CompaniesPage />)} />
      <Route path="/companies/product-search" element={withAuth(<ROUTES.ProductCompaniesPage />)} />
      <Route path="/company-map" element={withAuth(<ROUTES.CompanyMapPage />)} />
      <Route path="/companies/new" element={withAuth(<ROUTES.CreateCompanyPage />)} />
      <Route path="/companies/edit/:id" element={withAuth(<ROUTES.EditCompanyPage />)} />
      <Route path="/user/create" element={withAuth(<ROUTES.NewUserPage />)} />
      <Route path="/users" element={withAuth(<ROUTES.UsersPage />)} />
      <Route path="/product-portfolios" element={withAuth(<ROUTES.ProductPortfoliosPage />)} />
      <Route path="/product-portfolio/new" element={withAuth(<ROUTES.NewProductPortfolioPage />)} />
      <Route path="/product-portfolio/edit/:id" element={withAuth(<ROUTES.EditProductPortfolioPage />)} />
    </Routes>
  );
};

export default App;
