import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@flexboxapps/flbx-webapp-ui';
import CompanyContact from 'components/CompanyContact/CompanyContact';
import InputBlock from 'components/InputBlock/InputBlock';
import { CompanyContact as CompanyContactType } from 'reducers/companies/types';

interface CompanyContactsBlockProps {
  website?: string;
  contacts: CompanyContactType[];
  handleAddContact: () => void;
  handleContactChange: (selectedContactIndex: number, field: string, value: string) => void;
  handleDeleteContact: (index: number) => void;
  handleInputChange: (value: string, field: string) => void;
  fixHeight?: boolean;
}

const CompanyContactsBlock: FC<CompanyContactsBlockProps> = ({
  website = '',
  contacts,
  handleAddContact,
  handleDeleteContact,
  handleContactChange,
  handleInputChange,
  fixHeight = true,
}) => {
  const { t } = useTranslation();

  return (
    <InputBlock title="Céges kapcsolati adatok" fixHeight={fixHeight}>
      <Input
        label="cég weboldal URL"
        value={website}
        placeholder=""
        onChange={(e) => {
          handleInputChange(e.target.value, 'website');
        }}
      />

      {
        contacts.map((contact, index) => (
          <CompanyContact
            key={`contact-${index.toString()}`}
            index={index}
            contact={contact}
            handleDeleteContact={handleDeleteContact}
            handleContactChange={handleContactChange}
          />
        ))
      }
      <Button
        label={t('pages.companies.addContact') as string}
        type="ghost"
        onClick={handleAddContact}
      />
    </InputBlock>
  );
};

export default CompanyContactsBlock;
