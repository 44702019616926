import React, { FC, useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { DragDrop } from '@uppy/react';
import { createDataService } from 'services/api';
import Loader from 'components/Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import './image-upload.scss';

export interface ImageUploadProps {
  type: string;
  fieldName: string;
  imageSize?: number;
  onImageChange?: (id: string, field: string) => void;
  imageSource?: string | null;
}

const ImageUpload: FC<ImageUploadProps> = ({
  type,
  fieldName,
  imageSize = 360,
  onImageChange = () => { },
  imageSource = null,
}) => {
  let uploadParameters = {
    key: '',
    url: '',
    uploadId: '',
  };

  const [imageSrc, setImageSrc] = useState<string | null>(imageSource);

  useEffect(() => {
    setImageSrc(imageSource);
  }, [imageSource]);

  const [isUploading, setIsUploading] = useState(false);

  const uppy = new Uppy({
    autoProceed: true,
    restrictions: {
      maxFileSize: null,
    },
    onBeforeUpload: (files) => {
      const updatedFiles: any = {};
      Object.keys(files).forEach((fileID: any) => {
        updatedFiles[fileID] = {
          ...files[fileID],
          name: uploadParameters.key,
        };
      });
      return updatedFiles;
    },
  });

  uppy.use(AwsS3, {
    getUploadParameters: (file: any) => (
      createDataService('community-uploads', '/community-uploads', { type, fileType: file.meta.type }).then((response) => {
        uploadParameters = response.uploadParameters;

        return {
          method: 'PUT',
          url: uploadParameters.url,
          name: uploadParameters.key,
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': file.meta.type,
          },
        };
      })
    ),
  });

  uppy.on('file-added', () => {
    setIsUploading(true);
  });

  uppy.on('upload-success', (_, response) => {
    const { uploadURL = '' } = response;
    setImageSrc(uploadURL);
    setIsUploading(false);
    onImageChange(uploadParameters.uploadId, fieldName);
  });

  const removeImage = () => {
    uppy.cancelAll({ reason: 'user' });
    setImageSrc(null);
  };

  return (
    <div
      className="image-upload-container"
      style={{
        height: imageSize,
      }}
    >
      <Loader isLoading={isUploading} pageLoader>
        {
          !imageSrc
            ? (
              <DragDrop
                uppy={uppy}
                locale={{
                  strings: {
                    dropHereOr: 'Dobja ide a képet vagy kattintson a tallózáshoz',
                  },
                }}
              />
            )
            : (
              <div className="image-upload-box">
                <img
                  src={imageSrc}
                  alt="bejárat"
                  style={{
                    maxHeight: imageSize,
                  }}
                />
                <button
                  type="button"
                  className="remove-image-button"
                  onClick={removeImage}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    size="2x"
                    color="#E74C3C"
                  />
                </button>
              </div>
            )
        }
      </Loader>
    </div>
  );
};

export default ImageUpload;
