import React, { FC } from 'react';
import { Button, Input } from '@flexboxapps/flbx-webapp-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import './create-poll.scss';

export interface CreatePollProps {
  visible: boolean;
  values: {
    question: string;
    options: string[];
  };
  onChange: (key: string, value: string, index?: number) => void;
  onDeleteAnswer: (index: number) => void;
  onAddAnswer: () => void;
}

const CreatePoll: FC<CreatePollProps> = (props) => {
  const { visible, values, onChange, onDeleteAnswer, onAddAnswer } = props;
  return (
    <div className={`create-poll-container${visible ? ' visible' : ''}`}>
      <div className="create-poll-question-container">
        <Input
          label=""
          placeholder="Új szavazás"
          value={values.question}
          onChange={(e) => onChange('question', e.target.value)}
        />
      </div>
      <div className="create-poll-answers-container">
        {
          values.options.map((answer, index) => (
            <div className="create-poll-answer-container" key={`create-poll-answer-${index + 1}`}>
              <Input
                label=""
                placeholder={`${index + 1}. válaszlehetőség`}
                value={answer}
                onChange={(e) => onChange('options', e.target.value, index)}
              />
              {values.options.length > 2 ? (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  onClick={() => onDeleteAnswer(index)}
                  size="2x"
                  color="grey"
                />
              ) : <div style={{ width: '1em', height: '1em' }} />}
            </div>
          ))
        }
      </div>
      <Button
        label="Új válaszlehetőség megadása"
        onClick={onAddAnswer}
        type="ghost"
      />
    </div>
  );
};

export default CreatePoll;
