import React, { FC } from 'react';
import { Row, Col } from 'antd';
import InputBlock from 'components/InputBlock/InputBlock';
import SmartInput from './SmartInput';

interface CompanyAddressBlockProps {
  createCompany: {
    companyName: string,
    vatId: string,
    addressCountry: string,
    addressZip: string,
    addressCity: string,
    addressStreet: string,
    addressHouseNumber: string,
    addressFloor: string,
    addressDoor: string,
    addressDoorbell: string,
  },
  handleInputChange: (value: string, field: string) => void;
  onChange?: boolean;
}

const CompanyAddressBlock: FC<CompanyAddressBlockProps> = ({
  createCompany,
  handleInputChange,
  onChange = false,
}) => {
  const inputFields = [
    {
      title: '',
      rows: [
        [
          { label: 'cégnév', value: createCompany.companyName, name: 'companyName', span: 12 },
          { label: 'adószám', value: createCompany.vatId, name: 'vatId', span: 12 },
        ],
      ],
    },
    {
      title: 'Cím',
      rows: [
        [
          // { label: 'ország', value: createCompany.addressCountry, name: 'addressCountry', disabled: true, span: 8 },
          { label: 'irányítószám', value: createCompany.addressZip, name: 'addressZip', span: 8 },
          { label: 'város', value: createCompany.addressCity, name: 'addressCity', span: 16 },
        ],
        [
          { label: 'cím', value: createCompany.addressStreet, name: 'addressStreet', span: 16 },
          { label: 'házszám', value: createCompany.addressHouseNumber, name: 'addressHouseNumber', span: 8 },
        ],
        [
          { label: 'emelet', value: createCompany.addressFloor, name: 'addressFloor', span: 8 },
          { label: 'ajtó', value: createCompany.addressDoor, name: 'addressDoor', span: 8 },
          { label: 'kapucsengő', value: createCompany.addressDoorbell, name: 'addressDoorbell', span: 8 },
        ],
      ],
    },
  ];

  return (
    <InputBlock title="Alapadatok">
      {
        inputFields.map(({ title, rows }) => (
          <div key={`block-${title}`} style={{ paddingBottom: 18 }}>
            <h5>{title}</h5>
            {
              rows.map((row, index) => (
                <Row
                  key={`row-${index.toString()}`}
                  gutter={{ xs: 8, sm: 16 }}
                >
                  {
                    row.map(({ label, value, name, span }) => (
                      <Col span={span} key={`row-${index.toString()}-${name}`}>
                        <SmartInput
                          label={label}
                          initialValue={`${value}`}
                          name={name}
                          onBlur={!onChange ? (inputValue, inputName) => handleInputChange(inputValue, inputName) : undefined}
                          onChange={onChange ? (inputValue, inputName) => handleInputChange(inputValue, inputName) : undefined}
                        />
                      </Col>
                    ))
                  }
                </Row>
              ))
            }
          </div>
        ))
      }
    </InputBlock>
  );
};

export default CompanyAddressBlock;
