import React, { FC } from 'react';
import moment from 'moment';
import './post.scss';

export interface PostProps {
  createdAt: string;
  profilePicture?: string;
  author: string;
  content: string;
}

const Post: FC<PostProps> = ({
  profilePicture,
  createdAt,
  author = 'Szabó Eszter (adminisztrátor)',
  content,
}) => (
  <div className="post-container">
    <div className="post-header-container">
      {profilePicture ? (
        <img src={profilePicture} className="profile-picture" alt="musthavealtprop" />
      ) : (
        <div className="profile-picture dummy" />
      )}
      <div className="post-header-inner-container">
        <div className="post-author">{author}</div>
        <div className="post-created-date">{moment(createdAt).format('YYYY-MM-DD HH:mm')}</div>
      </div>
    </div>
    {/* eslint-disable-next-line react/no-danger */}
    <div className="post-body" dangerouslySetInnerHTML={{ __html: content }} />
  </div>
);

export default Post;
