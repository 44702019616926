import React, { FC } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import toolbarOptions from './toolbarOptions';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './text-editor.scss';

export interface TextEditorProps {
  content: EditorState;
  onChange: (content: EditorState) => void;
}

const TextEditor: FC<TextEditorProps> = ({ content, onChange }) => (
  <div className="text-editor-container">
    <Editor
      editorState={content}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      onEditorStateChange={onChange}
      toolbar={toolbarOptions}
    />
  </div>
);

export default TextEditor;
