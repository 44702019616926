import React, { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import { DropdownEntry } from 'reducers/companies/types';

interface CompanyServiceSelectProps {
  data: Array<DropdownEntry>;
  initialValue: Array<DropdownEntry>;
  disabled?: boolean,
  onSearch?: (event: any) => void;
  onBlur?: (event: any) => void;
  onSelect?: (value: any, option: any) => void;
  onDeselect?: (value: string) => void;
}

const CompanyServiceSelect: FC<CompanyServiceSelectProps> = ({
  data,
  initialValue,
  disabled = false,
  onBlur = () => { },
  onSearch = () => { },
  onDeselect = () => { },
  onSelect = () => { },
}) => {
  const { Option } = Select;
  const mappedData: any[] = [];
  const ownValue: any[] = [];

  data.forEach((d) => {
    mappedData.push(<Option key={d.key} value={d.label}>{d.label}</Option>);
  });

  initialValue.forEach((v) => {
    ownValue.push(<Option key={v.key} value={v.label}>{v.label}</Option>);
  });

  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (searchValue.length > 40) {
      setError(true);
      setSearchValue('');
    }
  }, [searchValue]);

  return (
    <>
      <Select
        {...initialValue}
        defaultValue={initialValue.map((item) => item.label)}
        mode="tags"
        style={{ width: '100%' }}
        disabled={disabled}
        onSelect={(event: any, option: any) => {
          onSelect(event, option);
          setSearchValue('');
        }}
        onDeselect={(value) => {
          onDeselect(value);
        }}
        onSearch={(event) => {
          if (error && event.length) {
            setError(false);
          }
          onSearch(event);
          setSearchValue(event);
        }}
        onBlur={onBlur}
        searchValue={searchValue}
      >
        {mappedData}
      </Select>
      {error && (
        <div className="error-message">
          A szolgáltatáskör szövegének hossza nem lehet több mint 40 karakter.
        </div>
      )}
    </>
  );
};

export default CompanyServiceSelect;
