import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint } from '@fortawesome/pro-regular-svg-icons';
import VerifyUser from 'components/VerifyUser/VerifyUser';
import { ReactComponent as LogoWideInverse } from 'assets/logo-wide-inverse.svg';
import { ReactComponent as LogoWide } from 'assets/logo-wide-normal.svg';

const VerifyUserPage: FC = () => {
  const useQuery = () => (
    new URLSearchParams(useLocation().search)
  );

  const { t } = useTranslation();
  return (
    <div className="page page-login">
      <div className="logo">
        <LogoWide className="logo-normal" />
        <LogoWideInverse width="196" className="logo-inverse" />
      </div>
      <div className="login-box">
        <div className="header-container">
          <FontAwesomeIcon icon={faFingerprint} />
          <div>
            <div className="form-title">Regisztráció</div>
            <div className="form-subtitle">{t('pages.login.subtitle') as string}</div>
          </div>
        </div>
        <VerifyUser
          queryEmail={useQuery().get('email') || ''}
          queryToken={useQuery().get('token') || ''}
        />
      </div>
    </div>
  );
};

export default VerifyUserPage;
