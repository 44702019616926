import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { Drawer, Button } from '@flexboxapps/flbx-webapp-ui';
import { Company, CompanyContact, CompanyService } from 'reducers/companies/types';
import { getFormattedAddress } from 'utils/companyData';
import './company-drawer.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { useNavigate } from 'react-router-dom';
import NavigationRoute from 'routes/NavigationRoute';

export interface CompanyDrawerProps {
  visible: boolean;
  onOpen: (visible: boolean) => void;
  companyDetails: Company | null;
}

const CompanyDrawer: FC<CompanyDrawerProps> = ({ visible, onOpen, companyDetails }) => {
  const { isAdmin, isLeader, companyId } = useSelector(({ user }: RootState) => user);
  const navigate = useNavigate();
  return (
    <Drawer
      className="app-drawer drawer-company-details"
      title={companyDetails?.companyName || ''}
      onClose={() => onOpen(false)}
      visible={visible}
    >
      <div className="drawer-inner-container">
        {
          companyDetails
          && (
            <Row gutter={[24, 24]}>
              {
                companyDetails.logo
                && (
                  <Col span={24}>
                    <div className="company-logo">
                      <img src={companyDetails.logo} alt={`${companyDetails.companyName} logo`} />
                    </div>
                  </Col>
                )
              }

              {
                !!companyDetails.companyServices
                && (
                  <Col span={24}>
                    {
                      companyDetails.companyServices.map((service: CompanyService) => (service.name)).join(', ')
                    }
                  </Col>
                )
              }

              <Col span={24}>
                <h2>Cím:</h2>
                {getFormattedAddress(companyDetails)}
              </Col>

              {
                (!!companyDetails.personalContacts.length || companyDetails.website)
                && (
                  <Col span={24}>
                    <h2>Kapcsolattartás:</h2>
                    <Row gutter={[24, 24]}>
                      {
                        companyDetails.website
                        && (
                          <Col span={24}>{companyDetails.website}</Col>
                        )
                      }
                      {
                        !!companyDetails.personalContacts.length
                        && companyDetails.companyContacts.map((contact: CompanyContact, index) => (
                          <Col key={`company-contact-${index.toString()}`} span={24}>
                            <Row gutter={[24, 3]}>
                              {
                                contact.label
                                && (
                                  <Col span={24}>{contact.label}</Col>
                                )
                              }
                              <Col span={24}>{contact.email}</Col>
                              <Col span={24}>{contact.phone}</Col>
                            </Row>
                          </Col>
                        ))
                      }
                    </Row>
                  </Col>
                )
              }

              {
                companyDetails.placeImage
                && (
                  <Col span={24}>
                    <div className="company-place-image">
                      <img src={companyDetails.placeImage} alt={`${companyDetails.companyName} bejárat`} />
                    </div>
                  </Col>
                )
              }

              {
                (isAdmin || (isLeader && companyId === companyDetails.id))
                && (
                  <Button
                    label="Cég szerkesztése"
                    type="primary"
                    onClick={() => navigate(`${NavigationRoute.EditCompany}/${companyDetails?._id}`)}
                  />
                )
              }
            </Row>
          )
        }
      </div>
    </Drawer>
  );
};
export default CompanyDrawer;
