import React, { FC } from 'react';
import './company-list-card.scss';

export interface CompanyListCardProps {
  companyName: string;
  logoSrc: string | null;
  companyServices?: string[];
  onClick?: () => void;
  isSmall?: boolean;
}

const CompanyListCard: FC<CompanyListCardProps> = ({
  companyName,
  logoSrc,
  companyServices = [],
  onClick,
  isSmall = false,
}) => {
  const servicesIntro = companyServices.slice(0, 4);
  return (
    <div
      className={`company-list-card${isSmall ? ' small' : ''}${onClick ? ' clickable' : ''}`}
      role="button"
      tabIndex={0}
      onClick={() => onClick && onClick()}
    >
      <div className="company-logo">
        {
          logoSrc
          && (
            <img src={logoSrc} alt={`${companyName} logo`} />
          )
        }
      </div>
      <div className="company-name">{companyName}</div>
      {
        !isSmall && (
          <div className="company-services">
            {
              servicesIntro.map((serviceName, index) => (
                <div
                  key={`company-service-${companyName}-${index.toString()}`}
                  className="company-service-label"
                >
                  {serviceName}
                </div>
              ))
            }
          </div>

        )
      }
      {
        !!companyServices.length && servicesIntro.length !== companyServices.length && !isSmall
        && (
          <div className="company-services-more">
            {`további ${companyServices.length - servicesIntro.length} témakör`}
          </div>
        )
      }
    </div>
  );
};

export default CompanyListCard;
