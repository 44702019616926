import React, { FC } from 'react';
import './input-block.scss';

export interface InputBlockProps {
  title: string;
  fixHeight?: boolean;
  customStyle?: React.CSSProperties;
  children: React.ReactNode;
  button?: React.ReactNode;
}

const InputBlock: FC<InputBlockProps> = ({
  title,
  children,
  fixHeight = false,
  customStyle = {},
  button = null,
}) => (
  <div className={`input-block${fixHeight ? ' fixHeight' : ''}`} style={customStyle}>
    <div className="input-block-title-container">
      <h1>{title}</h1>
      {button}
    </div>
    {children}
  </div>
);

export default InputBlock;
