import * as TYPES from 'consts/companiesActionTypes';
import { initialState, CompaniesState } from './initialState';
import { CompanyService, DropdownEntry } from './types';

const S3 = process.env.REACT_APP_S3_URL;

const companyReducer = (state = initialState, action?: TYPES.CompaniesActionTypes): CompaniesState => {
  if (!action) return initialState;
  switch (action.type) {
    case TYPES.COMPANIES_GET_COMPANY_LIST_R:
      return {
        ...state,
        companyListIsFetching: true,
        createCompany: initialState.createCompany,
        company: initialState.company,
      };

    case TYPES.COMPANIES_GET_COMPANY_LIST_S: {
      const { companyList } = action.payload;
      return {
        ...state,
        companyListIsFetching: false,
        companyList: companyList.map((item) => ({
          ...item,
          services: item.companyServices.map((service: CompanyService) => (service.name || '')),
          logo: item.logo
            ? `${S3}/logos/${item.logo}`
            : null,
          placeImage: item.placeImage
            ? `${S3}/places/${item.placeImage}`
            : null,
        })),
        companyFetchingError: null,
      };
    }

    case TYPES.COMPANIES_GET_COMPANY_LIST_F: {
      const { companyFetchingError } = action.payload;
      return {
        ...state,
        companyListIsFetching: false,
        companyFetchingError,
      };
    }

    case TYPES.SET_CREATE_COMPANY_FIELD: {
      const { field, value } = action.payload;
      return {
        ...state,
        createCompany: {
          ...state.createCompany,
          [field]: value,
        },
      };
    }

    case TYPES.GET_COMPANY_SERVICE_LIST_S: {
      const { companyServiceList } = action.payload;
      return {
        ...state,
        companyServiceList,
      };
    }

    case TYPES.GET_PRODUCT_PORTFOLIO_LIST_S: {
      const { productPortfolioList } = action.payload;
      return {
        ...state,
        productPortfolioList,
      };
    }

    case TYPES.CREATE_PRODUCT_PORTFOLIO_S: {
      const { productPortfolio } = action.payload;
      return {
        ...state,
        productPortfolio,
        productPortfolioSucceeded: true,
      };
    }

    case TYPES.CREATE_PRODUCT_PORTFOLIO_F:
    case TYPES.CREATE_PRODUCT_PORTFOLIO_R:
      return {
        ...state,
        productPortfolioSucceeded: false,
      };

    case TYPES.EDIT_PRODUCT_PORTFOLIO_S: {
      const { productPortfolio } = action.payload;
      return {
        ...state,
        productPortfolio,
        productPortfolioSucceeded: true,
      };
    }

    case TYPES.EDIT_PRODUCT_PORTFOLIO_F:
    case TYPES.EDIT_PRODUCT_PORTFOLIO_R:
      return {
        ...state,
        productPortfolioSucceeded: false,
      };

    case TYPES.DELETE_PRODUCT_PORTFOLIO_S:
      return {
        ...state,
        productPortfolioLoading: false,
        productPortfolioSucceeded: true,
      };

    case TYPES.DELETE_PRODUCT_PORTFOLIO_F:
      return {
        ...state,
        productPortfolioLoading: false,
        productPortfolioSucceeded: false,
      };

    case TYPES.DELETE_PRODUCT_PORTFOLIO_R:
      return {
        ...state,
        productPortfolioLoading: true,
        productPortfolioSucceeded: false,
      };
    
    case TYPES.CREATE_COMPANY_SERVICE_R:
      return {
        ...state,
        companyServiceIsEditing: true,
      };

    case TYPES.CREATE_COMPANY_SERVICE_S: {
      const { companyService, type } = action.payload;
      const newItem = { key: companyService.id, label: companyService.name } as DropdownEntry;
      if (type === 'create') {
        return {
          ...state,
          companyServiceIsEditing: false,
          createCompany: { ...state.createCompany, companyServices: [...state.createCompany.companyServices, newItem] },
        };
      }
      return {
        ...state,
        companyServiceIsEditing: false,
        company: state.company ? { ...state.company, companyServices: [...state.company.companyServices, newItem] } : undefined,
      };
    }

    case TYPES.ADD_SERVICE_TO_COMPANY: {
      const { companyService, type } = action.payload;
      if (type === 'create') {
        return {
          ...state,
          createCompany: { ...state.createCompany, companyServices: [...state.createCompany.companyServices, companyService] },
        };
      }
      return {
        ...state,
        company: state.company ? { ...state.company, companyServices: [...state.company.companyServices, companyService] } : undefined,
      };
    }

    case TYPES.COMPANIES_CREATE_COMPANY_R:
      return {
        ...state,
        createCompanySucceeded: false,
        createCompanyLoading: true,
      };

    case TYPES.COMPANIES_CREATE_COMPANY_F:
      return {
        ...state,
        createCompanySucceeded: false,
        createCompanyLoading: false,
      };

    case TYPES.COMPANIES_CREATE_COMPANY_S:
      return {
        ...state,
        createCompanySucceeded: true,
        createCompanyLoading: false,
      };

    case TYPES.DELETE_SERVICE_FROM_COMPANY: {
      const { companyService, type } = action.payload;
      if (type === 'create') {
        const companyServices = state.createCompany.companyServices.filter((c) => c.label !== companyService);
        return {
          ...state,
          companyServiceList: !companyServices.length ? [] : state.companyServiceList,
          createCompany: { ...state.createCompany, companyServices },
        };
      }
      const companyServices = state.company ? state.company.companyServices.filter((c) => c.label !== companyService) : [];
      return {
        ...state,
        companyServiceList: !companyServices.length ? [] : state.companyServiceList,
        company: state.company ? { ...state.company, companyServices } : undefined,
      };
    }

    case TYPES.GET_COMPANY_R:
      return {
        ...state,
        companyListIsFetching: true,
        companyFetched: false,
      };

    case TYPES.GET_COMPANY_S: {
      const { company } = action.payload;
      return {
        ...state,
        companyListIsFetching: false,
        companyFetched: true,
        company: {
          ...company,
          logo: company.logo || null,
          placeImage: company.placeImage || null,
          companyServices: company.companyServices.map((item) => ({ key: item.id, label: item.name })),
        },
        companyFetchingError: null,
      };
    }

    case TYPES.GET_COMPANY_F: {
      const { companyFetchingError } = action.payload;
      return {
        ...state,
        companyListIsFetching: false,
        companyFetchingError,
        companyFetched: false,
      };
    }

    case TYPES.COMPANIES_EDIT_COMPANY_R:
      return {
        ...state,
        companyEditFailed: false,
        companyEditSucceeded: false,
        companyEditLoading: true,
      };

    case TYPES.COMPANIES_EDIT_COMPANY_F:
      return {
        ...state,
        companyEditFailed: true,
        companyEditSucceeded: false,
        companyEditLoading: false,
      };

    case TYPES.COMPANIES_EDIT_COMPANY_S:
      return {
        ...state,
        companyEditFailed: false,
        companyEditSucceeded: true,
        companyEditLoading: false,
      };

    case TYPES.COMPANIES_EDIT_SUCCEEDED:
      return {
        ...state,
        companyEditFailed: false,
        companyEditSucceeded: false,
        companyEditLoading: false,
      };

    case TYPES.COMPANIES_SEARCH_COMPANY_LIST_R:
      return {
        ...state,
        companyListIsFetching: true,
        createCompany: initialState.createCompany,
        company: initialState.company,
        productFilteredCompanies: [],
      };

    case TYPES.COMPANIES_SEARCH_COMPANY_LIST_S: {
      const { productFilteredCompanies } = action.payload;
      return {
        ...state,
        companyListIsFetching: false,
        productFilteredCompanies: productFilteredCompanies.map((item) => ({
          ...item,
          services: item.companyServices.map((service: CompanyService) => (service.name || '')),
          logo: item.logo
            ? `${S3}/logos/${item.logo}`
            : null,
          placeImage: item.placeImage
            ? `${S3}/places/${item.placeImage}`
            : null,
        })),
        companyFetchingError: null,
      };
    }

    case TYPES.COMPANIES_SEARCH_COMPANY_LIST_F: {
      const { companyFetchingError } = action.payload;
      return {
        ...state,
        companyListIsFetching: false,
        companyFetchingError,
      };
    }

    case TYPES.COMPANIES_SEARCH_COMPANY_LIST_INIT:
      return {
        ...state,
        productFilteredCompanies: [],
        companyListIsFetching: false,
        companyFetchingError: null,
      };

    case TYPES.GET_COMPANY_SERVICE_LIST_R:
    case TYPES.GET_COMPANY_SERVICE_LIST_F:
    case TYPES.CREATE_COMPANY_SERVICE_F:
      return state;

    default:
      return state;
  }
};

export default companyReducer;
