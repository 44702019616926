import { Users } from './types';

export type UserState = {
  isAuthenticating: boolean,
  isAuthenticated: boolean,
  hasAuthenticatingError: boolean,
  errorMessage: string,
  email?: string,
  userId: string,
  isAdmin: boolean;
  isLeader: boolean;
  companyId: string;
  givenName: string;
  userCreated: boolean;
  verifyLoading: boolean;
  verifySucceeded: boolean;
  users: Users | null;
  isSendingEmail: boolean;
  isEmailSent: boolean;
  sendingEmailError: boolean;
  forgotPasswordSucceeded: boolean;
  forgotPasswordError: boolean;
  restoreUserFailed: boolean;
};

export const initialState: UserState = {
  restoreUserFailed: false,
  isAuthenticating: false,
  isAuthenticated: false,
  hasAuthenticatingError: false,
  errorMessage: '',
  companyId: '',
  isLeader: false,
  email: undefined,
  isAdmin: false,
  userId: '',
  givenName: '',
  userCreated: false,
  verifyLoading: false,
  verifySucceeded: false,
  users: null,
  isSendingEmail: false,
  isEmailSent: false,
  sendingEmailError: false,
  forgotPasswordSucceeded: false,
  forgotPasswordError: false,
};
