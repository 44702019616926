import React, { FC } from 'react';
import './whats-new.scss';

export interface UpdateItem {
  date: string;
  update: string;
}

export interface WhatsNewProps {
  items: UpdateItem[];
}

const WhatsNew: FC<WhatsNewProps> = ({
  items,
}) => (
  <div className="whats-new">
    <div className="whats-new-title">✨ Újdonságok</div>
    <div className="whats-new-content">
      {
        items.map(({ date, update }) => (
          <div key={date} className="whats-new-item">
            <div className="whats-new-item-date">{date}</div>
            <div className="whats-new-item-update">{update}</div>
          </div>
        ))
      }
    </div>
  </div>
);

export default WhatsNew;
